import React, { useState } from 'react'
import { Image, Form, Col, Button, Modal } from 'react-bootstrap'
import CONSTANTS from '../constants'
import { Estimate, Screens } from '../models/models'

const ScreenCount = (props: { setEstimate: any, estimate: Estimate, previousPage: () => void, nextPage: () => void }) => {

    const [validated, setValidated] = useState(true)

    let screens: Screens = props.estimate.Screens ?? {}

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        props.estimate.Screens = screens
        props.setEstimate(props.estimate)
        props.nextPage()

        setValidated(true);
    };

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Do you want your screens cleaned?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Row>
                    {/* How many normal screens? */}
                    <Col md="6" xs="12">
                        <Image src="screen-half.jpg" thumbnail />
                    </Col>
                    <Col md="6" xs="12">
                        <Form.Group controlId="formHowHeard">
                            <div className="m-1">
                                <Form.Label><h5><strong>How many normal screens do you have?</strong> </h5></Form.Label>
                                <Form.Label><i>Normal screens only cover half of the window.</i></Form.Label>
                                <Form.Control
                                    min={0}
                                    onChange={e => screens.normal = parseInt(e.target.value)}
                                    defaultValue={props.estimate.Screens?.normal ?? ""}
                                    type="number" placeholder="How many normal screens do you have?" />
                            </div>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="mt-4">
                    <Col md="6" xs="12">
                        <Image src="solar-screen.jpg" thumbnail />
                    </Col>
                    <Col md="6" xs="12">
                        <Form.Group controlId="formHowHeard">
                            <div className="m-1">
                                <Form.Label><h5><strong>How many sun screens do you have?</strong></h5></Form.Label>
                                <Form.Label><i>Sun screens cover the entire window and are made to keep the sun out of your home.  They can be white, beige, black, or white.</i></Form.Label>
                                <Form.Control
                                    min={0}
                                    onChange={e => screens.sun = parseInt(e.target.value)}
                                    defaultValue={props.estimate.Screens?.sun ?? ""}
                                    type="number" placeholder="How many normal sun screens do you have?" />
                            </div>
                        </Form.Group>
                    </Col>
                    {/* How many sun screens? */}


                </Form.Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => props.previousPage()}>Previous</Button>
                <Button variant="primary" onClick={handleSubmit} >Next</Button>                
            </Modal.Footer>

        </div>
    )

}

export default ScreenCount