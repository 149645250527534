import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './crm-layout.css';
import { AppBar, AppBarProps, createTheme, CssBaseline, CSSObject, Divider, styled, Theme, Toolbar, Typography, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Box, ThemeProvider } from '@mui/system';
import SidebarMenu from '../components/sidebarMenu';
import CRM from './crm';
import { useParams } from 'react-router-dom';
import CONSTANTS from '../constants';
import Inventory from './inventory';
import Screens from './screens';
import Appointments from '../pages/appointments';
import Invoices from './invoices';
import { ChevronLeft, ChevronRight, Image } from '@mui/icons-material';
import Finances from './finances';
import AppointmentPage from './appointmentPage';

const CRMLayout = () => {

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const drawerWidth = 240;

    let { setting, appointmentId } = useParams<any>()

    const [open, setOpen] = React.useState(false);

    const theme = useTheme();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'light',
        },
    });

    const openedMixin = (theme: Theme): CSSObject => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<AppBarProps>(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar style={{ backgroundColor: "#282828" }} position="fixed" open={open} >
                    <Toolbar>
                        <IconButton
                            color="default"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                color: "white",
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img className="mr-2" src="/logo-notext-web.png" alt="Dephyned Logo" style={{ height: "50px" }} />
                        <Typography variant="h6" noWrap component="div">
                            Defined Home Services
                        </Typography>
                    </Toolbar>
                </AppBar>
                {
                    /* 
                    For information on about how this drawer works, see: https://mui.com/material-ui/react-drawer/ 
                    The full demo is located here: https://codesandbox.io/s/ih4dm1?file=/demo.tsx:4894-4924
                    */
                }
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton style={{ color: "white" }} onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <SidebarMenu open={open} drawerWidth={drawerWidth} />
                </Drawer>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, bgcolor: 'background.default' }}>
                    <DrawerHeader />
                    {
                        setting === CONSTANTS.WEBVIEW_SETTINGS.CUSTOMERS && <CRM />
                    }
                    {
                        setting === CONSTANTS.WEBVIEW_SETTINGS.INVENTORY && <Inventory />
                    }
                    {
                        setting === CONSTANTS.WEBVIEW_SETTINGS.SOLAR_SCREENS && <Screens />
                    }
                    {
                        setting === CONSTANTS.WEBVIEW_SETTINGS.INVOICES && <Invoices />
                    }
                    {
                        setting === CONSTANTS.WEBVIEW_SETTINGS.APPOINTMENTS && <Appointments />
                    }
                    {
                        setting === CONSTANTS.WEBVIEW_SETTINGS.FINANCES && <Finances />
                    }
                    {
                        setting === CONSTANTS.WEBVIEW_SETTINGS.APPOINTMENT && <AppointmentPage appointmentId={appointmentId} />
                    }

                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default CRMLayout;
