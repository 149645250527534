import React, { useState } from 'react'
import { Modal, Form, Col, Button } from 'react-bootstrap'
import CONSTANTS from '../constants'
import { Estimate, Misc } from '../models/models'
import firebase from 'firebase'

const EndEstimator = (props: { setEstimate: any, estimate: Estimate, previousPage: () => void, nextPage: () => void }) => {

    const [validated, setValidated] = useState(false)

    let misc: Misc = props.estimate.Misc ?? {}

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        props.estimate.Misc = misc        
        props.setEstimate(props.estimate)
        saveQuote(props.estimate)
        props.nextPage()
        setValidated(true);

    }

    const saveQuote = (estimate: any) => {
        const db = firebase.firestore()

        db.collection('online-quotes').add(estimate)        
    }

    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>General Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>

                        {/* Anything else */}
                        <Form.Group as={Col} xs="12" controlId="formContactEmail">
                            <Form.Label>Is there anything else that you'd like to tell us?</Form.Label>
                            <Form.Control
                                onChange={e => misc.additionalInfo = e.target.value}
                                defaultValue={props.estimate.Misc?.additionalInfo ?? ""}
                                as="textarea" rows={4} />
                        </Form.Group>

                        {/* Email */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formContactEmail">
                            <Form.Label>Is it okay if we contact you through email?</Form.Label>
                            <Form.Control
                                onChange={e => misc.canEmail = e.target.value == CONSTANTS.YES ? true : false}
                                defaultValue={props.estimate.Misc?.canEmail == true ? CONSTANTS.YES : CONSTANTS.NO}
                                as="select" size="lg" custom>
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>

                        {/* Phone Number  */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formContactPhoneNumber">
                            <Form.Label>Is it okay if we contact you through text or call you?</Form.Label>
                            <Form.Control
                                onChange={e => misc.canText = e.target.value == CONSTANTS.YES ? true : false}
                                defaultValue={props.estimate.Misc?.canText == true ? CONSTANTS.YES : CONSTANTS.NO}
                                as="select" size="lg" custom>
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>

                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => props.previousPage()}>Previous</Button>
                    <Button variant="primary" onClick={handleSubmit} >Get Estimate</Button>
                </Modal.Footer>
            </Form>
        </div>
    )

}

export default EndEstimator