import firebase from 'firebase'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Row, Button, Container, Table, Alert, Toast } from 'react-bootstrap'
import { Button as MaterialButton } from '@mui/material'
import Customer from '../components/customer'
import CONSTANTS from '../constants'
import { Message, PayingClient, SurveyObject } from '../models/models'
import MessageService from '../services/messageService'
import './crm.css'
import SideBar from '../components/sidebar'

const CRM = () => {

    const [showCustomerDiaolog, setShowCustomerDialog] = useState(false)

    /// The customers that we already have saved to the system
    const [customers, setCustomers] = useState<PayingClient[]>([])

    /// The customer that has been selected
    const [selectedCustomer, setSelectedCustomer] = useState<PayingClient>()

    const [surveys, setSurveys] = useState<SurveyObject[]>()

    const [messagesAlreadySent, setMessagesAlreadySent] = useState<Message[]>()

    /// Any errors that happen on the page
    const [error, setError] = useState<string>()

    /// Any notifications that need to be shown to the user
    const [notification, setNotification] = useState<string>()

    const createNewCustomer = () => {
        setSelectedCustomer(undefined)
        setShowCustomerDialog(true)
    }

    useEffect(() => {

        if (firebase.apps.length == 0) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
                authDomain: 'dephyned-web.firebaseapp.com',
                projectId: 'dephyned-web'
            });
        }

        const db = firebase.firestore()

        db.collection(CONSTANTS.FIRESTORE.SURVEYS).get().then(result => {
            let documents = result.docs.map(doc => doc.data())

            let surveys = documents as SurveyObject[]
            setSurveys(surveys)
        })

        db.collection('clients').orderBy(CONSTANTS.FIRESTORE.DATE_OF_SERVICE, "desc").onSnapshot(result => {
            let documents = result.docs.filter(doc => doc.data()["archived"] != true).map(doc => doc.data())

            let payingClients = documents as PayingClient[]
            setCustomers(payingClients)
        })

        db.collection(CONSTANTS.FIRESTORE.MESSAGES).get().then(result => {
            let documents = result.docs.map(doc => doc.data())
            setMessagesAlreadySent(documents as Message[])
        })
    }, [])

    const sendPostcard = (customer: PayingClient) => {
        const messageService = new MessageService()
        messageService.sendPostcard(customer).then(result => {
            if (result == true) {
                setNotification("Postcard setup successfully")
            }
        }).catch(error => {
            setError(error)
        })
    }

    const sendSurvey = (customer: PayingClient) => {

        if (!customer.phoneNumber) { return }
        if (!canSendMessage(customer)) { return }

        let messageText = `Hi ${customer.firstName}! This is Ade with Defined Window Cleaning.

I hope that all is well! We're so happy that we had the privilege of working with you.  We would really like to know how we did and if there's any improvements that we need to make.

If you have a few moments, would you mind filling out a brief 6-question survey? The link to the survey is below.
    
Thank you so much!

${"https://windowcleaning-vegas.com/survey"}`

        let messageService = new MessageService()
        messageService.sendNotification(customer, messageText, CONSTANTS.MESSAGETYPES.REMINDER).then(result => {
            if (result == true) {
                setNotification("Message sent successfully")
            }
        }).catch(error => {
            setError(error)
        })
    }

    const sendReminder = (customer: PayingClient) => {

        if (!customer.phoneNumber) { return }
        if (!canSendMessage(customer)) { return }

        let messageText = `Hi ${customer.firstName}! This is Ade with Defined Window Cleaning.
        
It's been a while since your last cleaning and we want to make sure everything is still looking good. Go ahead and text me back if you'd like to schedule your next cleaning.
            
Thanks!`

        let messageService = new MessageService()
        messageService.sendNotification(customer, messageText, CONSTANTS.MESSAGETYPES.REMINDER).then(result => {
            if (result == true) {
                setNotification("Message sent successfully")
            }
        }).catch(error => {
            setError(error)
        })
    }

    const canSendMessage = (customer: PayingClient) => {
        const message = messagesAlreadySent?.filter(message => message.phoneNumber == customer.phoneNumber)

        if (moment.unix(customer.dateOfService.seconds).diff(new Date()) >= 0) {
            setError("You have not done work for this customer yet.")
            return false
        }

        if (new Date().getHours() < 9) {
            setError("It's too early to send a message to someone.")
            return false
        }

        if (message && message.length > 0) {
            const canNotSendMessage = new MessageService().messageSentWithinThirtyDays(message[0].date)
            if (canNotSendMessage) {
                setError("You have already sent a message to this person within the last 30 days.")
                return false
            }
        }

        return true
    }

    const getIncomingMoney = () => {
        return customers.filter(c => moment.unix(c.dateOfService.seconds).diff(new Date()) > 0).reduce((sum, customer) => sum + (customer.costOfService ?? 0), 0)
    }

    return (
        <div style={{ backgroundColor: "white" }} className="ml-5">
            <Toast show={error != undefined} onClose={() => setError(undefined)} className="fixed-top">
                <Toast.Header>
                    <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>{error}</Toast.Body>
            </Toast>

            <Toast show={notification != undefined} onClose={() => setNotification(undefined)} className="fixed-top">
                <Toast.Header>
                    <strong className="me-auto">Notification</strong>
                </Toast.Header>
                <Toast.Body>{notification}</Toast.Body>
            </Toast>

            <div  >
                <Container className="large-screen" >
                    <Row>
                        <Col xs="12">
                            <h1>Customers</h1>
                        </Col>
                        <Col xs="12">
                            These are all the customers that are already in the system. Either we've done work for them or we will be doing work for them in the future.
                        </Col>
                        <Col xs="12" className="mt-3">
                            <Button onClick={createNewCustomer}>
                                Create Customer
                            </Button>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col xs="12">
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Survey</th>
                                        <th>Date of Survey</th>
                                        <th>Services</th>
                                        <th>Referrals</th>
                                        <th>Contacted Referrals</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        surveys?.map((survey, index) => {
                                            return (
                                                <tr style={{ backgroundColor: "white" }}>
                                                    <td>{index}</td>
                                                    <td>{survey.name ?? ""}</td>
                                                    <td>{survey.email ?? ""}</td>
                                                    <td>
                                                        <p>
                                                            •  What did the customer enjoy about the service?: {survey.enjoyedAboutService}
                                                        </p>
                                                        <p>
                                                            •  Did the customer feel they got their money worth?: {survey.moneysWorth}
                                                        </p>
                                                        <p>
                                                            •  Where can we improve?: {survey.improvements}
                                                        </p>
                                                        <p>
                                                            •  What rating would you give us?: {survey.rating}
                                                        </p>
                                                    </td>
                                                    <td>{moment.unix(survey.dateAdded?.seconds).format("MM/DD/YYYY")}</td>
                                                    <td>
                                                        {
                                                            survey.servicesProvided?.join(", ")
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            survey.referrals?.map(referral => {
                                                                return (
                                                                    <div>
                                                                        {referral.name} - {referral.contactInfo}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            survey.contactedReferrals == true ? "Yes" : "No"
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Alert variant='warning'>
                        <Row>
                            <Col><h1>Stats</h1></Col>
                        </Row>
                        <Row className="my-3">
                            <Col>
                                <h5>• You have <strong>{customers.length}</strong> customers currently in the system.</h5>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h5>• After today, you have <strong>${getIncomingMoney()}</strong> of projected income coming in this month.</h5>
                            </Col>
                        </Row>
                    </Alert>

                    <Row className="mt-5">
                        <Col xs="12">
                            <Table responsive hover size="sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ width: 150 }}>First Name</th>
                                        <th style={{ width: 150 }}>Last Name</th>
                                        <th style={{ width: 300 }}>Address</th>
                                        <th style={{ width: 150 }}>Phone Number</th>
                                        <th style={{ width: 200 }}>Date of Service</th>
                                        <th>Price</th>
                                        <th style={{ width: 400 }}>Services</th>
                                        <th>Communication</th>
                                        <th style={{ width: 300, textAlign: "center" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customers.sort((a, b) => {
                                            if (new Date(a.dateOfService) > new Date(b.dateOfService)) {
                                                return -1
                                            }

                                            return 1
                                        }).map((customer, index) => {
                                            return (
                                                <tr>
                                                    <td>{index}</td>
                                                    <td>{customer.firstName}</td>
                                                    <td>{customer.lastName}</td>
                                                    <td>{customer.address}</td>
                                                    <td>{customer.phoneNumber}</td>
                                                    <td>{moment.unix(customer.dateOfService.seconds).format("MM/DD/YYYY")}</td>
                                                    <td>${customer.costOfService}</td>
                                                    <td>
                                                        {
                                                            customer.servicesProvided?.join(", ")
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            messagesAlreadySent?.filter(message => message.phoneNumber == customer.phoneNumber).map(message => {
                                                                return (
                                                                    <div>
                                                                        <p>
                                                                            • Sent a <strong>{message.type}</strong> message on {moment.unix(customer.dateOfService.seconds).format("MM/DD/YYYY")}
                                                                        </p>

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                    {


                                                        <td colSpan={3}>
                                                            <div>
                                                                <MaterialButton fullWidth={true} style={{ margin: "auto", padding: "auto" }} variant="contained" color='primary' onClick={() => sendSurvey(customer)}>Send Survey</MaterialButton>
                                                            </div>
                                                            <div className="mt-2">
                                                                <MaterialButton fullWidth={true} variant="contained" color='info' onClick={() => sendReminder(customer)}>Send Reminder</MaterialButton>
                                                            </div>
                                                            <div className="mt-2">
                                                                <MaterialButton fullWidth={true} style={{ margin: "auto", padding: "auto" }} color='primary' variant="contained" onClick={() => sendPostcard(customer)}>Send Postcard</MaterialButton>
                                                            </div>
                                                            <div className="mt-2">
                                                                <MaterialButton fullWidth={true} variant="contained" onClick={() => {
                                                                    setShowCustomerDialog(true)
                                                                    setSelectedCustomer(customer)
                                                                }}>Edit Customer</MaterialButton>
                                                            </div>
                                                        </td>

                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                {
                    showCustomerDiaolog && <Customer showModal={showCustomerDiaolog} setShowModal={setShowCustomerDialog} customer={selectedCustomer} />
                }
            </div>
        </div >
    )

}

export default CRM