import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import Home from './pages/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { hotjar } from 'react-hotjar'
import ReactGA from 'react-ga'
import LiveChat from 'react-livechat'
import firebase from 'firebase'
import PowerWashing from './pages/powerwashing';
import Survey from './pages/survey';
import Screens from './pages/screens';
import CRMLayout from './pages/crm-layout';
import Appointments from './pages/appointments';
import AppointmentPage from './pages/appointmentPage';

function App() {

  const phoneNumberLink = "tel:7027470901"

  useEffect(() => {
    hotjar.initialize(2197434, 0)
    ReactGA.initialize('UA-84318222-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (firebase.apps.length == 0) {      
      // Initialize Cloud Firestore through Firebase
      firebase.initializeApp({
        apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
        authDomain: 'dephyned-web.firebaseapp.com',
        projectId: 'dephyned-web'
      });
    }
  }, [])

  return (
    <Router>
      <div style={{ backgroundColor: "#dae9f7" }}>
        <LiveChat license='11554178' />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />                      
          <Route path="/screens" element={<Screens />} />
          <Route path="/survey" element={ <Survey /> } />    
          <Route path="/appointments" element={ <Appointments /> } />                                
          <Route path="/crm" element={ <CRMLayout /> } />
          <Route path="/crm/:setting" element={ <CRMLayout /> } />          
          <Route path="/powerwashing" element={ <PowerWashing /> } />          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
