import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Row, Col, Button, Form, InputGroup, Modal, ModalBody } from 'react-bootstrap'
import CONSTANTS from '../constants'
import { Estimate, GeneralInformation } from '../models/models'

const GeneralInformationForm = (props: { setEstimate: any, estimate: Estimate, previousPage: () => void, nextPage: () => void }) => {

    const YELP = "Yelp"

    let generalInformation: GeneralInformation = props.estimate.GeneralInformation ?? {}

    const [validated, setValidated] = useState(false)

    const [showModal, setShowModal] = useState(false)

    const [showCommercialModal, setShowCommercialModal] = useState(false)

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (generalInformation.houseSize == CONSTANTS.HOUSESIZE.HUGE) {      
            setShowModal(true)      
            return
        }

        if (generalInformation.residential == false) {
            setShowCommercialModal(true)
            return
        }

        props.estimate.GeneralInformation = generalInformation
        props.setEstimate(props.estimate)
        props.nextPage()

        setValidated(true);
    };    

    const HouseTooBig = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>House Too Big For Instant Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    For houses 5000 sq ft or larger we need to do the estimate in person.  Please call or text us now so we can provide you your estimate.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" href="tel:7027470901" onClick={() => {
                        setShowModal(false)                        
                    }} >Call or Text (702) 747-0901</Button>
                    <Button variant="danger" onClick={() => setShowModal(false)} >Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const CommercialJob = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setShowCommercialModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Need to call</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Sorry, but you can't use hte instant quote service for commercial buildings. Please call or text us now so we can provide you your estimate.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" href="tel:7027470901" onClick={() => {
                        setShowCommercialModal(false)                        
                    }} >Call or Text (702) 747-0901</Button>
                    <Button variant="danger" onClick={() => setShowCommercialModal(false)} >Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div>
            {
                showModal && <HouseTooBig />
            }
            {
                showCommercialModal && <CommercialJob />
            }
            <Modal.Header closeButton>
                <Modal.Title>General Information</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Row>
                        {/* How hear about us */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formHowHeard">
                            <Form.Label>Where did you hear about us?</Form.Label>
                            <Form.Control
                                onChange={e => generalInformation.howHearAboutUs = e.target.value}
                                defaultValue={props.estimate.GeneralInformation?.howHearAboutUs ?? ""}
                                as="select" size="lg" custom>
                                <option>{YELP}</option>
                                <option>Google</option>
                                <option>Bing</option>
                                <option>Flyer</option>
                                <option>Referral</option>
                                <option>Real Estate Agent</option>
                            </Form.Control>
                        </Form.Group>

                        {/* Number of floors */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formFloors">
                            <Form.Label>Number of floors above ground?</Form.Label>
                            <Form.Control as="select" size="lg"
                                onChange={e => generalInformation.floorsAboveGround = e.target.value}
                                defaultValue={props.estimate.GeneralInformation?.floorsAboveGround ?? ""} custom>
                                <option> {CONSTANTS.FLOORS.ONE_STORY} </option>
                                <option> {CONSTANTS.FLOORS.TWO_STORY} </option>
                                <option> {CONSTANTS.FLOORS.THREE_STORY} </option>
                            </Form.Control>
                        </Form.Group>

                        {/* Has basement */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formBasement">
                            <Form.Label>Do you have a basement?</Form.Label>
                            <Form.Control as="select" size="lg"
                                onChange={e => generalInformation.basement = e.target.value == CONSTANTS.BASEMENT.YES ? true : false}
                                defaultValue={props.estimate.GeneralInformation?.basement == true ? CONSTANTS.YES : CONSTANTS.NO} custom>
                                <option>{CONSTANTS.BASEMENT.NO}</option>
                                <option> {CONSTANTS.BASEMENT.YES} </option>
                            </Form.Control>
                        </Form.Group>

                        {/* House size */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formHouseSize">
                            <Form.Label>House size</Form.Label>
                            <Form.Control as="select" size="lg"
                                onChange={e => generalInformation.houseSize = e.target.value}
                                defaultValue={props.estimate.GeneralInformation?.houseSize ?? ""} custom>
                                <option> {CONSTANTS.HOUSESIZE.SMALLEST} </option>
                                <option> {CONSTANTS.HOUSESIZE.SMALLER} </option>
                                <option> {CONSTANTS.HOUSESIZE.SMALL} </option>
                                <option> {CONSTANTS.HOUSESIZE.MEDIUM} </option>
                                <option> {CONSTANTS.HOUSESIZE.MEDIUMLARGE} </option>
                                <option> {CONSTANTS.HOUSESIZE.LARGE} </option>
                                <option> {CONSTANTS.HOUSESIZE.VERYLARGE} </option>
                                <option> {CONSTANTS.HOUSESIZE.HUGE} </option>
                            </Form.Control>
                        </Form.Group>

                        {/* Residential or Commercial */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formHouseSize">
                            <Form.Label>Residential or Commercial</Form.Label>
                            <Form.Control
                                onChange={e => generalInformation.residential = e.target.value == CONSTANTS.RES_COMMERCIAL.RES ? true : false}
                                defaultValue={props.estimate.GeneralInformation?.residential == true ? CONSTANTS.RES_COMMERCIAL.RES : CONSTANTS.RES_COMMERCIAL.COM}
                                as="select" size="lg" custom>
                                <option> {CONSTANTS.RES_COMMERCIAL.RES} </option>
                                <option> {CONSTANTS.RES_COMMERCIAL.COM} </option>
                            </Form.Control>
                        </Form.Group>

                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => props.previousPage()}>Previous</Button>
                    <Button variant="primary" onClick={handleSubmit} >Next</Button>                    
                </Modal.Footer>
            </Form>
        </div>
    )
}

export default GeneralInformationForm