import { url } from 'inspector'
import moment from 'moment'
import React, { useState } from 'react'
import { Button, Card, CardColumns, Col, Container, Image, Modal, Navbar, Row } from 'react-bootstrap'
import '../App.css'
import TestimonialObject from '../objects/testimonialobject'
import ReactGA from 'react-ga'
import Estimator from '../components/estimator'
import '../components/quote.css'
import Pricing from '../components/pricing'

const Home = () => {

    const phoneNumberLink = "tel:7027470901"
    const phoneNumber = "(702) 747-0901"
    const discount = "20%"
    const [showModal, setShowModal] = useState(false)
    const [showDangerModal, setShowDangerModal] = useState(false)

    const CTA = () => {
        return (
            <div style={{ textAlign: "center", borderWidth: "1px", borderColor: "black", borderStyle: "dashed" }} className="alert alert-info p-2">
                <p>Join the <strong>479 Las Vegas and Henderson customers</strong> who trust us with their window cleaning needs!</p>
                <p className="mb-0 pb-0">Get your windows cleaned <strong>before {moment().add(10, 'd').format('MMMM Do')}</strong> and get
                    <p className="mt-3" style={{ fontSize: "60px" }}>
                        <strong>20% OFF</strong>
                    </p>
                </p>

                <Button className="mt-3" href={phoneNumberLink} onClick={() => { markConversion() }}>Click to call or text 📞 {phoneNumber} NOW  for your<br />FREE estimate<br />(We accept calls 24 hrs a day)</Button>
                <p style={{ margin: "0px" }} >or</p>
                <p>
                    To redeem your 20% Off. Fill out the Online Estimate Form by clicking on the button below. Then schedule a time and date for your window cleaning. To receive the 20% off you must book your window cleaning before {moment().add(10, 'd').format('MMMM Do')}
                </p>
                <div className="mb-3">
                    <Button onClick={() => setShowModal(true)}>Click Here To Get Your Instant Quote</Button>
                </div>

            </div>
        )
    }

    const markConversion = () => {
        ReactGA.event({
            category: 'User',
            action: 'User clicked phone number to call',
            label: "User clicked call button",
            value: 10
        })
    }

    const CallNow = () => {
        return (
            <Button className="mt-3" href={phoneNumberLink}>Click to call or text 📞 {phoneNumber} NOW for your<br /><strong>FREE</strong> estimate</Button>
        )
    }

    const Testimonial = (props: {
        testimonial: TestimonialObject
    }) => {

        return (
            <Card bg="light" className="mb-2">
                <Card.Header>{props.testimonial.username} says:</Card.Header>
                <Card.Body>
                    <div className="mb-3">
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                    </div>
                    <Card.Title>{props.testimonial.title} </Card.Title>
                    <Card.Text>
                        {props.testimonial.content}
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

    const AreYouSure = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setShowDangerModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    If you go back you'll lose any information that you've entered. Are you sure you want to go back?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        setShowModal(false)
                        setShowDangerModal(false)
                    }} >Yes</Button>
                    <Button variant="success" onClick={() => setShowDangerModal(false)} >Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div>
            {
                showDangerModal && <AreYouSure />
            }
            <Modal show={showModal} size="lg" onHide={() => setShowDangerModal(true)}>
                <Estimator />
            </Modal>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src="/logo.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Defined Window Cleaning
                </Navbar.Brand>
                <Button className="m-2" style={{}} onClick={() => { markConversion() }} href={phoneNumberLink}>Call (702) 747-0901 now<br />to schedule your <strong>FREE ESTIMATE</strong></Button>

                <Button className="m-2" onClick={() => setShowModal(true)}>Click Here To Get Your Instant Quote</Button>

            </Navbar>


            <div style={{ width: "100%", fontSize: "18px", lineHeight: "2.0em" }} className="mt-4" >
                <Container className="p-3" style={{ backgroundColor: "white" }}>
                    <Row>
                        <Col>
                            <a href="/">Window Cleaning</a> {'|'} <a href="/powerwashing">Power Washing</a> {'|'} <a href="/#screen-repair">Screen Repair and Building</a>
                        </Col>
                    </Row>

                    <Row className="text-center">
                        <Col xs="12" md="6" className="d-flex align-items-center text-left">
                            <div >
                                <h1 style={{ fontSize: "45px", fontWeight: "bold" }}>Best In Las Vegas Window Cleaning</h1>
                                <div style={{ fontSize: "20px" }} className="text-left mt-2" >
                                    <p>We clean <strong>windows, screens, and tracks.</strong> Call us NOW for a <strong>free ESTIMATE.</strong></p>
                                    <p>We clean:
                                        <ul>
                                            <li>Inside, outside, or both</li>
                                            <li>1-story &amp; 2-story buildings</li>
                                            <li>Residential and business</li>
                                            <li>Luxury and non-luxury homes</li>
                                            <li>Screens included with every service</li>
                                            <li>Window sills included in every service</li>
                                            <li>Power wash driveway and patios</li>
                                            <li>Pressure wash exterior walls of your home</li>
                                            <li>(<strong>Note:</strong> We also can repair or rebuild any missing or damaged screens.)</li>
                                        </ul>
                                    </p>
                                    <p><strong>All our reviews are 5 stars</strong> ⭐⭐⭐⭐⭐. <span style={{ textDecoration: "underline" }}>We work hard</span> on your windows to keep it that way.</p>
                                    <p><strong>* Licensed and Insured up to $1 million</strong></p>
                                </div>
                            </div>
                        </Col>
                        <Col xs="12" md="6" className="my-4 d-flex align-items-center">
                            <Container>
                                <Row>
                                    <Col xs="12">
                                        <Image src="/profile.JPG" thumbnail />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                            <CTA />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" className="my-5">
                            <h1 className="text-center">Below Are Examples Of Our Previous Work<br />For Clients Here In Las Vegas</h1>
                            <hr />
                        </Col>
                        <Col>
                            <CardColumns>
                                <Card>
                                    <Card.Img src="gallary8.jpeg" />
                                    <Card.Body>
                                        Look at the difference between how the window looked before we cleaned it and after. This home in Centennial Hills was a lot of work and <strong>the customer loved the results</strong>.
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img src="window-dirty-before-after.jpg" />
                                    <Card.Body>
                                        This image gives you a good idea of how clean your windows will look when we're done.
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img src="hazy-before-after.jpg" />
                                    <Card.Body>These windows were covered with a thick film. We had to use our water fed pole machine and a special cleaning brush to remove it properly. But look at how clean we were able to get it.</Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img src="paint-spray-before-after.png" />
                                    <Card.Body>Previous window cleaners had come to clean these windows but did not remove any of the paint spray. We came and removed the paint spray free of charge.</Card.Body>
                                </Card>
                            </CardColumns>
                        </Col>
                    </Row>
                    <div className="my-5">
                        <Pricing />                        
                    </div>
                    <Row>
                            <Col>
                                <CTA />
                            </Col>
                        </Row>
                    <Row>
                        <Col>
                            <CardColumns>

                                <Card>
                                    <Card.Img src="tracks-before-after.jpg" />
                                    <Card.Body>
                                        Look how clean we got these filthy tracks! We had to really scrub to get it cleaned, but the results were worth the effort.
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Img src="gallary13.jpg" />
                                    <Card.Body>
                                        We can clean homes of any size. This home was 16,000 Square Feet, but it was no problem. We have the team and the equipment to take care of it!
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Img src="gallary16.jpg" />
                                    <Card.Body>
                                        Luxury home we cleaned in Anthem. We cleaned these windows with a Water Fed Pole.
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img src="/tracks1.jpg" />
                                    <Card.Body>
                                        Look at how clean we got these window tracks
                                    </Card.Body>
                                </Card>
                            </CardColumns>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mt-5" id="water-fed-pole-design">
                            <h2>We serve Las Vegas, Henderson, and Boulder City</h2>
                            <hr />

                            <p>In Vegas, windows get dusty! And <strong>unfiltered Vegas water CANNOT be used to clean our windows because it will leave spots.</strong> 😬 That's why we work extra hard though to ensure that your windows look great after our cleaning services. <strong>We always use natural products, and we make sure that when cleaning your windows we scrub every inch until they look like new.</strong></p>
                            <p>Also...</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-5" id="water-fed-pole-design">
                            <h2>Our custom-built water-fed R.O. system leaves fewer spots and streaks!</h2>
                            <hr />
                            <p>We <strong>built our own $2,000+ water-fed R.O. system</strong> so we can remove more impurities from our Las Vegas water, cut through the desert dust, and <strong>LEAVE FEWER SPOTS &amp; STREAKS</strong>!</p>
                            <h4>How our water-fed pole cleans better</h4>
                            <p>
                                Our Water-Fed Pole is equipped with a natural Hog’s Hair brush, <strong>which allows us to really get the windows clean due to its special scrubbing ability.</strong>
                            </p>
                            <ol>
                                <li><strong>We use two types of water filtration units.</strong>
                                    <ul>
                                        <li>The first is a RO filter. The RO filter removes the larger particles from the water.</li>
                                        <li>The second is the DI filter. The DI filter removes the tiny particles that leave a spots and streaks on your glass.</li>
                                    </ul>
                                </li>
                                <li>After the water goes through our dual filtration unit, <strong>the water we use to clean your windows is ultra-pure</strong>
                                    <ul>
                                        <li><a href="https://www.ktnv.com/news/las-vegas-has-2nd-hardest-water-in-the-nation#:~:text=Water%20is%20considered%20%22hard%22%20when,it%20is%20%22extremely%20hard.%22">Our city <strong>tap water here in Las Vegas is 278 parts-per-million</strong></a> (Reference: KTNV ABC Channel 13, Published: June 17, 2019, Accessed: November 26, 2020)</li>
                                        <li>A bottle of <a href="https://en.wikipedia.org/wiki/Dasani#:~:text=Dasani%20has%20%3C35%20ppm%20of%20total%20dissolved%20mineral%20salts."><strong>Dasani water has around 35 parts-per-million</strong></a> (Reference: Wikepedia.org, Article: "Dasani", Accessed: November 26, 2020).</li>
                                    </ul>
                                </li>
                            </ol>
                            <p>
                                <span style={{ color: "#FF0000" }}>The ultra-pure water coming out of <span style={{ textDecoration: "underline", fontWeight: "bold" }}>our water fed pole is less than 3 parts-per-million.</span></span> The water coming out of our custom-built system is so good, on average there is <span style={{ color: "#FF0000" }}>only 1 grain in every 6 gallons of water.</span>
                            </p>
                            <p>That's how our custom-built system helps us get your windows cleaner, with fewer spots and streaks.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1 className="text-center">Testimonials</h1>
                            <hr />

                            <div className="mt-5">
                                <CardColumns>
                                    <Testimonial testimonial={{ username: "Patricia I", title: "From Home Advisor", content: '"Our House Cleaner suggested that we have the windows of our house professionally cleaned because professionals would do a much better job than her. I’m so happy that we followed her suggestion. Defined Cleaning, did a superb job! We are so happy with the results! All the windows and screens are very clean. With the Sliding Door, I could walk into it, not realizing that the sliding door wasn’t opened. That’s how clean it is. Thank you, Defined Cleaning, for a professional job well done. I will recommend your services to others. – PE, A very satisfied customer."' }} />

                                    <Testimonial testimonial={{ username: "Alex T.", title: "From Home Advisor", content: '"Ade was on time and very professional. Our windows look great! We would recommend his window cleaning services to anyone."' }} />

                                    <Testimonial testimonial={{ username: "Andrew K.", title: "From Home Advisor", content: '"These guys did a great job. Showed up on time, performed a thorough job. Definitely will hire them next time."' }} />

                                    <Testimonial testimonial={{ username: "Meredith K.", title: "From Google Reviews", content: '"Fast friendly service. I would highly recommend!"' }} />

                                    <Testimonial testimonial={{ username: "Barek H.", title: "From Google Reviews", content: '"I like to tell people whenever I come across an honest business. You can really trust what Ade bids for the job. He was trained by a professional and does an excellent job. I don’t know what he does with the screens, but they look awesome. They are my new go to window cleaners."' }} />

                                    <Testimonial testimonial={{ username: "Jessie T.", title: "From Google Reviews", content: '"Defined Window cleaning did an amazing job...Our windows look amazing."' }} />

                                    <Testimonial testimonial={{ username: "Joseph W.", title: "From Google Reviews", content: "'I don't think my windows had been cleaned in 10 years, but Jesse and Fernando got them looking like new once again. No need to look anywhere else, go with this company, you won't regret it.'" }} />

                                    <Testimonial testimonial={{ username: "Diana S.", title: "From Yelp", content: "'Defined Window Cleaning did an amazing job! My house was getting ready to close and the mud storm had totally dirtied the windows. I had to pack and had no time to redo them. They came out in less 24 hrs and did a fantastic job at a reasonable price. The screens were as good as new, too!'" }} />

                                    <Testimonial testimonial={{ username: "Celeste C.", title: "From Yelp", content: "'Ade is very skilled and trustworthy. You can trust him to do what he says in a timely manner. Your windows will be sparkling clean in no time!'" }} />

                                    <Testimonial testimonial={{ username: "Portia H.", title: "From Yelp", content: "'If there's 10 stars here I would give him that! Ade is someone you can trust with your home and your window cleaning. He is very professional, has a very calm manner to him, and will exceed to your expectations on what your paying for. He listens to your request and will give you an honest bid, which we need nowadays. He will make sure that you will call him back for his service and you won't regret either referring him to your friends!'" }} />

                                </CardColumns>
                            </div>
                            <CTA />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <h1>Screen cleaning is included in every service</h1>
                            <hr />
                            <div className="my-5 text-center">
                                <Image src="/screen1.jpg" width="300px" rounded />
                            </div>
                            <p>
                                Every window cleaning really should include a screen cleaning.  Why is that?  Because especially here in Las Vegas, screens can get very, very dirty!  <strong>So if you don’t clean the screens, than your going to have a job that doesn’t look finished.</strong>  Really, not cleaning your window screens is like only cleaning your car windows, but not the rest of the car.  That obviously would be a huge no, no.  So why do that with your home?  If you’re going to spend the money to get your windows cleaned, than a screen cleaning has to be included in that. There’s just really no other option. That’s why <strong>we throw in the screens for FREE!</strong> But why else should you get those screens cleaned?
                            </p>

                            <h5>Effect of Not Cleaning the Window Screens</h5>

                            <p>
                                If you clean the window and do not clean the screen, what will be the result?  Water will go through the screen, pick up dirt, and than end up on your window.  That’s the last thing that you would want to happen after you’ve just spent money to get the windows cleaned, right?  <strong>This is why we always include a screen cleaning with your window cleaning service.</strong>  It’s our way of <strong>making sure your windows stay clean much longer.</strong>  But what is included in this service?  Here’s the five-step process:
                            </p>

                            <ol>
                                <li>First we carefully remove the screens from the window frames</li>
                                <li>We clean the excess dirt off your screens by brushing them vigorously with soap and water</li>
                                <li>We than let the screens sit outside in the sun to dry</li>
                                <li>Than we clean the outside frames of the screens.</li>
                                <li>Finally, we put the screens back into the window frames that they came from.</li>
                            </ol>

                            <p>
                                <strong>This five-step process leaves your window screens looking amazing.  People say that when we are done, their screens look better than brand new.</strong> This is a must have service that <strong>we are more than happy to provide free of charge.</strong>
                            </p>

                            <CTA />
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col>
                            <h1 className="my-5">Get window cleaning specific to our Las Vegas needs</h1>
                            <hr />

                            <div className="my-5 text-center">
                                <Image src="/gallary8.jpeg" rounded />
                            </div>

                            <p>
                                Las Vegas has tons of sun.  Almost every day of the year, homes have the sun beat down on their windows.  And what is the result of this?  Well, windows become much harder to clean because the sun basically bakes the dirt and grime into the window.  Is this impossible to get off?  Most of the times no.  But there are some things that must be done in order to get the windows properly cleaned.  So with that in mind, what do we do to make sure that your windows are cleaned like they should be?
                            </p>
                            <h3>Scrubbing and Water Fed Pole</h3>
                            <p>
                                We will do one of two things.  <strong>The first is we will scrub the windows with steel wool.  This steel wool is super fine.  It’s so fine that it won’t scratch the window at all.  The steel wool will get that stuck on dirt off those windows, and make them sparkle like brand new.</strong>
                            </p>

                            <p>
                                Something else that we do is we use a Water-Fed System.  A Water-Fed System is a tool which first purifies water and then sends it through a pole with a brush at the end, which we than use to scrub and clean your windows. (To <a href="#water-fed-pole-design">learn more about our custom-built, water-fed RO system</a> please refer to the information above.)
                            </p>

                            <p>
                                After we are done with the cleaning we also will check the home and make sure that we’ve left no streaks by using a dry piece of steel wool to clean up any areas that we missed.
                            </p>
                            <h3>
                                The Inside of a Las Vegas Home
                            </h3>
                            <p>
                                When we are on the inside of the home, we take extreme care to make sure that everything is out of the way of the windows.  This is to ensure that we do not accidentally hit or damage any of your belongings.  Also, <strong>we put towels down on the floor and around the window frame to ensure that we do not get any excess water anywhere where it shouldn’t be.</strong>  We will also be polite and use booties or take off our shoes when inside your home. And with COVID-19 right now, <strong>we'll never enter your home without wearing a mask.</strong>
                            </p>

                            <p>
                                <strong>We use only eco-friendly solutions that will not damage anything within your home</strong> as well.  So you can <strong>feel good knowing that your home is safe when we are in there for the cleaning</strong>.
                            </p>

                            <p>
                                All in all, you will be very happy with the window washing services provided.
                            </p>
                            <CTA />
                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col>
                            <h1 className="text-center">Why Your Screens Will Look Better Than Brand New</h1>
                            <hr />
                            <div className="my-5 text-center">
                                <Image src="/screen1.jpg" width="300px" rounded />
                            </div>
                            <p>
                                Our screens separate us from most window cleaners.  We use a solution on them that makes them shine like brand new.  Customers love our screens!  This is something we add free of charge!
                            </p>

                            <p>
                                If you clean the window and do not clean the screen, what will be the result?  Water will go through the screen, pick up dirt, and than end up on your window.  That’s the last thing that you would want to happen after you’ve just spent money to get the windows cleaned, right?  <strong>This is why we always include a screen cleaning with your window cleaning service.</strong>  It’s our way of <strong>making sure your windows stay clean much longer.</strong>  But what is included in this service?  Here’s our seven-step process:
                            </p>

                            <ol>
                                <li>First we carefully remove the screens from the window frames</li>
                                <li>We clean the excess dirt off your screens by brushing them vigorously with soap and water</li>
                                <li>We than let the screens sit outside in the sun to dry</li>
                                <li>Than <strong>we apply a solution called Screen Magic which eliminates the excess dirt from your window screens and repels dirt from clinging to the screens later.  It also causes the screens to shine like they’re brand new.</strong></li>
                                <li>We than allow the screens to dry again</li>
                                <li>Than we clean the outside frames of the screens.</li>
                                <li>Finally, we put the screens back into the window frames that they came from.</li>
                            </ol>

                            <p>
                                This seven-step process leaves your window screens looking amazing. <strong>People say that when we are done, their screens look better than brand new.</strong> And the great thing is that your screens will continue to look great, because <strong>the solution will continue to repel the dirt.</strong>  This is a must have service that <strong>we are more than happy to provide free of charge.</strong>
                            </p>

                            <CTA />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1>We clean window tracks and window sills</h1>
                            <hr />
                            <div className="my-5 text-center">
                                <Image src="/tracks1.jpg" rounded />
                            </div>
                            <p>
                                What is a window sill?  Why does it need to be cleaned?  Well, a window really consist of four things:
                            </p>

                            <p>
                                <ol>
                                    <li>Glass panes</li>
                                    <li>Window screens</li>
                                    <li>The window tracks</li>
                                    <li>The window sills</li>
                                </ol>
                            </p>
                            <p>
                                A window cleaning, should obviously involve cleaning ALL four parts, otherwise, it would be called a partial-window cleaning.  The sills is basically another way of saying the window frames.  It’s the outer edge of the window that is made of some form of plastic or metal.  <strong>The sills have to be cleaned in order to really have a clean looking window.</strong>  If you’ve ever seen a window with clean glass but dirty sills, you’ll notice that something is quiet wrong.
                            </p>

                            <p>
                                But what about the tracks?
                            </p>
                            <p>
                                <strong>In Las Vegas, window tracks get disgusting.</strong>  If you let them go for any serious amount of time, than you will have a thick layer of dust in the tracks.  This does not look good at all.  And also, when water splashes on the tracks, than it will end up getting onto the glass and onto the screens.  So you can see why it’s so important to make sure that you clean those tracks.  So how do we get them clean?
                            </p>
                            <strong>
                                <ol>
                                    <li>We use a towel to loosen up the dirt.</li>
                                    <li>Than, after we’ve cleaned the glass, we use a wet towel to get those tracks looking perfect.</li>
                                </ol>
                            </strong>
                            <p>
                                On the inside of the home we use a wet towel to clean the rest of the dirt out of those tracks.
                            </p>
                            <p>
                                The end result?  Beautiful tracks, which make for a beautiful window.  So what are you waiting for.  Click on the button below to schedule your free at-home estimate now, and get your windows cleaned like never before.
                            </p>

                            <CTA />
                        </Col>
                    </Row>
                    <hr />
                    <div id="screen-repair">
                        <Row className="my-5">
                            <Col xs="12" md="6">
                                <div>
                                    <h1 style={{ fontSize: "45px", fontWeight: "bold" }}>Window Screen Repair and Installation</h1>
                                </div>
                                <div>
                                    <h5>We construct and install window screens.</h5>
                                </div>
                                <div className="mt-4">
                                    Because of the intense Las Vegas sun, window screens fade over time and become brittle. Even the frames themselves can become brittle and the corners can easily break.
                                </div>

                                <div>
                                    <strong>
                                        If you have...
                                        <ul>
                                            <li>Old or bent screens frames</li>
                                            <li>Faded or torn window screens</li>
                                            <li>Have windows that need screens put on them</li>
                                        </ul>
                                    </strong>
                                </div>
                                <div>
                                    ...then call us now for your <strong>FREE at home estimate.</strong>  We will come out, measure all your windows and give you your personalized quote.
                                </div>
                                <div className="text-center mb-4">
                                    <CallNow />
                                </div>
                            </Col>
                            <Col xs="12" md="6" className="text-center my-4 d-flex align-items-center">
                                <Image src="/screen-rebuild.jpg" thumbnail />
                            </Col>
                        </Row>
                    </div>

                </Container>
                <hr />
            </div >
        </div >
    )
}

export default Home