import { Alert, Button, Paper, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Modal, Card, CardGroup, CardColumns } from 'react-bootstrap'
import { Appointment, PayingClient } from '../models/models'
import Customer from '../components/customer'
import './appointments.css'
import moment from 'moment'
import { Logtail } from '@logtail/browser'
import { AccessTimeOutlined, AccountCircleOutlined, ArrowCircleLeftOutlined, ArrowCircleRightOutlined, CleaningServices, LocationOnOutlined, PaidOutlined } from '@mui/icons-material'
import CONSTANTS from '../constants'

type Week = {
    Monday: Appointment[],
    Tuesday: Appointment[],
    Wednesday: Appointment[],
    Thursday: Appointment[],
    Friday: Appointment[],
    Saturday: Appointment[]
}

const Appointments = () => {

    const [clients, setClients] = useState<{}>({})

    const [appointments, setAppointments] = useState<Appointment[]>()

    const [selectedAppointment, setSelectedAppointment] = useState<Appointment>()

    const [showCreateAppointment, setShowCreateAppointment] = useState(false)

    const logtail = new Logtail("QfdZVpuGoBnDV3GopF7y9oos")

    if (firebase.apps.length == 0) {
        logtail.info("Initializing firebase")
        // Initialize Cloud Firestore through Firebase
        firebase.initializeApp({
            apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
            authDomain: 'dephyned-web.firebaseapp.com',
            projectId: 'dephyned-web'
        });
    }

    const [week, setWeek] = useState<Week>({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: []
    })

    let [currentWeek, setCurrentWeek] = useState(0)

    const getAppointments = async (from: Date, to: Date) => {
        const db = firebase.firestore()

        logtail.info("Getting appointments")

        // Get all the appointments that are after yesterday
        const myAppointments = (await db.collection('appointments').where('dateOfService', '>', from).where('dateOfService', '<', to)
            .get()).docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                } as Appointment
            }).map(appointment => {
                return {
                    ...appointment,
                    dateOfService: moment(appointment.dateOfService.toDate())
                }
            })

        logtail.info("Retrieved appointments", {
            appointments: myAppointments.map(appointment => appointment.id).join(',')
        })

        setAppointments(myAppointments)

        const myWeek: Week = {
            Monday: myAppointments.filter(appointment => appointment.dateOfService.day() == 1),
            Tuesday: myAppointments.filter(appointment => appointment.dateOfService.day() == 2),
            Wednesday: myAppointments.filter(appointment => appointment.dateOfService.day() == 3),
            Thursday: myAppointments.filter(appointment => appointment.dateOfService.day() == 4),
            Friday: myAppointments.filter(appointment => appointment.dateOfService.day() == 5),
            Saturday: myAppointments.filter(appointment => appointment.dateOfService.day() == 6)
        }

        setWeek(myWeek)
    }

    useEffect(() => {
        setAppointments(undefined)
        getAppointments(moment().startOf('week').add(7 * currentWeek, 'days').toDate(), moment().endOf('week').add(7 * currentWeek, 'days').toDate())

    }, [currentWeek])

    useEffect(() => {

        getAppointments(moment().startOf('week').toDate(), moment().endOf('week').toDate())
    }, [])

    useEffect(() => {

        const myClients = clients

        const getClients = async () => {
            if (appointments == undefined) return

            return await Promise.all(appointments.sort((a, b) => {
                if (a.dateOfService > b.dateOfService) {
                    return -1
                } else {
                    return 1
                }
            }
            ).map(async (appointment) => {
                const customer = await loadCustomer(appointment.clientId, appointment.id)
                myClients[appointment.id] = customer
            }))
        }

        getClients().then(() => {
            // We create a new object because otherwise React doesn't see the change in the state
            setClients(new Object(myClients))
        })

    }, [appointments])


    /**
     * It creates an appointment object, then adds it to the database and the state
     * @param {PayingClient} payingClient - PayingClient
     */
    const createAppointment = (appointment: Appointment, client: PayingClient) => {
        logtail.info("Creating appointment", {
            appointmentId: appointment.id,
            clientId: client.id
        })

        const db = firebase.firestore()

        db.collection('appointments').doc(appointment.id).set(appointment).then(() => {
            console.log('Appointment created')
            setAppointments([...appointments ?? [], appointment])

            logtail.info("Appointment created", {
                appointmentId: appointment.id,
                clientId: client.id
            })
        }).catch((error) => {
            console.log(error)

            logtail.error("Error creating appointment", {
                appointmentId: appointment.id,
                clientId: client.id,
                error: error
            })
        })

        setAppointments([...appointments ?? [], appointment])

    }

    const getAppointmentTypeId = (hours: number, services: string[]) => {

        if (services.some(service => service.includes("Screen"))) {
            switch (hours) {
                case 1: return 41128191
                case 2: return 41128204
                case 3: return 41128220
                case 4: return 41128228
                case 5: return 41128231
                case 6: return 41128236
                case 7: return 41128443
                default: return 41128448
            }
        }
        else if (services.some(service => service.includes("Window"))) {
            switch (hours) {
                case 1: return 16635721
                case 2: return 39980877
                case 3: return 40345092
                case 4: return 40345584
                case 5: return 40345587
                case 6: return 40345591
                case 7: return 41128406
                default: return 41128433
            }
        }
    }

    /**
     * It takes an appointment and a client, and adds the appointment to Accuity
     * @param {Appointment} appointment - Appointment - This is the appointment object that was created in
     * the previous step.
     * @param {PayingClient} client - PayingClient - This is the client that the appointment is created for
     */
    const addAppointmentToAccuity = async (appointment: Appointment, client: PayingClient) => {
        const startDate = moment(appointment.dateOfService).format('YYYY-MM-DD')
        const startTime = moment(`${startDate} ${appointment.startTime}`).format('YYYY-MM-DD HH:mm')
        const endTime = moment(`${startDate} ${appointment.endTime}`).format('YYYY-MM-DD HH:mm')

        const hours = moment.duration(moment(endTime).diff(moment(startTime))).asHours()

        let body = {
            smsOptIn: false,
            datetime: startTime,
            appointmentTypeID: getAppointmentTypeId(hours, appointment.servicesProvided),
            calendarID: 4408364,
            firstName: client.firstName,
            lastName: client.lastName,
            phone: client.phoneNumber ?? ""
        }

        if (client.email) {
            body["email"] = client.email
        }

        // Add the appointment to Accuity
        const response = await fetch('https://defined-cleaning.herokuapp.com/addToAccuity', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(body)
        })

        console.log(await response.json())
    }

    const selectAppointment = (appointment: Appointment) => {
        setSelectedAppointment(appointment)
        setShowCreateAppointment(true)
    }

    const sortAppointmentsByTime = (appointments: Appointment[]) => {

        const sorted = appointments.sort((a, b) => {
            const aTime = moment(a.startTime, "HH:mm")
            const bTime = moment(b.startTime, "HH:mm")

            if (aTime.isBefore(bTime)) return -1
            return 1
        })

        return sorted
    }

    /**
     * It loads a customer from the database and sets it in the state.
     * @param {string} id - the id of the client
     * @param {string} appointmentId - the id of the appointment
     * @returns The customer object
     */
    const loadCustomer = async (id: string, appointmentId: string) => {
        const db = firebase.firestore()
        const customer = (await db.collection('clients').doc(id).get()).data() as PayingClient
        return customer
    }

    return (
        <div className="large-screen">
            <Container>
                {
                    showCreateAppointment &&
                    <Customer showModal={showCreateAppointment} setShowModal={setShowCreateAppointment} scheduleAppointment={createAppointment} customerId={selectedAppointment?.clientId}
                    />
                }
                <Row>
                    <Col>
                        Defined Home Services
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <div>
                            <h1>Appointments</h1>
                        </div>

                    </Col>
                </Row>
                <Row className="mt-1 mb-5">
                    <Col>
                        <Button variant='contained' onClick={() => {
                            setSelectedAppointment(undefined)
                            setShowCreateAppointment(true)
                        }}>Create Appointment</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ color: "#2976D2", fontWeight: "normal" }} className="my-2">
                            {
                                currentWeek == 0 &&
                                <span><strong>CURRENT WEEK - </strong></span>
                            }
                            Week of {moment().startOf('week').weekday(7 * currentWeek).format('MMMM Do YYYY')}
                            <Button className="mx-2" variant='contained' onClick={() => setCurrentWeek(currentWeek - 1)}><ArrowCircleLeftOutlined /></Button>
                            <Button className="mx-2" variant='contained' onClick={() => setCurrentWeek(currentWeek + 1)}><ArrowCircleRightOutlined /></Button>
                        </div>
                        <hr />
                    </Col>
                </Row>
                {
                    appointments === undefined &&
                    <Row>
                        <Col>
                            <Alert severity='info'>Loading appointments...</Alert>
                        </Col>
                    </Row>
                }
                {
                    appointments !== undefined && appointments.length == 0 &&
                    <Row>
                        <Col>
                            <Alert severity='info'>No appointments for this week</Alert>
                        </Col>
                    </Row>
                }
                {
                    appointments !== undefined && appointments.length > 0 &&
                    <div>
                        {
                            Object.keys(week).map((day, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            week[day].length == 0 &&
                                            <div key={index} className="my-5">
                                                <Row >
                                                    <Col>
                                                        <h3>{day}</h3>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col>
                                                        <Alert severity='info'>No appointments for this day</Alert>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        {
                                            week[day].length > 0 &&
                                            <div className="my-5">
                                                <Row key={index}>
                                                    <Col>
                                                        <h3>{day}</h3>
                                                    </Col>
                                                </Row>
                                                {/* <CardColumns> */}
                                                {

                                                    sortAppointmentsByTime(week[day]).map((appointment: Appointment, index: number) => {
                                                        return (
                                                            <div>


                                                                <Card className="my-2">
                                                                    <Card.Header style={{ backgroundColor: index === moment().day() - 1 ? "#2976D2" : "white" }} >
                                                                        <strong>{clients[appointment.id]?.firstName} {clients[appointment.id]?.lastName}</strong>
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        <Row className="my-3">
                                                                            <Col xs="12" lg="4">
                                                                                <strong>Start Time:</strong>
                                                                            </Col>
                                                                            <Col xs="12" lg="8">
                                                                                {moment(appointment.startTime, "HH:mm").format('hh:mm A')}
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="my-3">
                                                                            <Col xs="12" lg="4">
                                                                                <strong>Address:</strong>
                                                                            </Col>
                                                                            <Col xs="12" lg="8">
                                                                                <div>
                                                                                    <div>
                                                                                        {clients[appointment.id]?.address}
                                                                                    </div>
                                                                                    <div>
                                                                                        <Button target="_blank" className="mt-2" variant="contained" href={`https://www.google.com/maps/dir/?api=1&destination=${clients[appointment.id]?.address}`}>Navigate</Button>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="my-3">
                                                                            <Col xs="12" lg="4">
                                                                                <strong>Notes:</strong>
                                                                            </Col>
                                                                            <Col xs="12" lg="8">
                                                                                <div>
                                                                                    <div>
                                                                                        {appointment.notes}
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="my-3">
                                                                            <Col xs="12" lg="4">
                                                                                <strong>Services Provided:</strong>
                                                                            </Col>
                                                                            <Col xs="12" lg="8">
                                                                                - {appointment.servicesProvided.join('\r\n- ')}
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="my-3">
                                                                            <Col xs="12" lg="4">
                                                                                <strong>Total:</strong>
                                                                            </Col>
                                                                            <Col xs="12" lg="8">
                                                                                ${appointment.total}
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>

                                                            </div>
                                                        )
                                                    })

                                                }
                                                {/* </CardColumns> */}
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </Container >

        </div >
    )
}

export default Appointments
