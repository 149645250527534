import { Button, TextField } from '@mui/material'
import firebase from 'firebase'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CONSTANTS from '../constants'

type Item = {
    name: string,
    itemId: string,
    cost: number,
    description: string,
    quantity: number
}

const Inventory = () => {

    const [name, setName] = React.useState("")
    const [itemId, setItemId] = React.useState("")
    const [cost, setCost] = React.useState(0)
    const [description, setDescription] = React.useState("")
    const [quantity, setQuantity] = React.useState(0)
    const [items, setItems] = React.useState<Item[]>([])
    const [item, setItem] = React.useState<Item>({} as Item)

    const [error, setError] = React.useState("")
    const [success, setSuccess] = React.useState("")

    useEffect(() => {
        console.log("Inventory page loaded")
        if (firebase.apps.length == 0) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: CONSTANTS.FIREBASE.apiKey,
                authDomain: CONSTANTS.FIREBASE.authDomain,
                projectId: CONSTANTS.FIREBASE.projectId
            });
        }

        const db = firebase.firestore()

        db.collection("inventory").get().then((querySnapshot) => {
            const myItems: Item[] = []
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                const item: Item = doc.data() as Item
                myItems.push(item)
            });

            setItems(myItems)
        })
    }, [])

    /**
     Adds an item to the inventory collection in the database if it does not exist or updates the item if it does exist
     */
    const save = () => {

        if (name === "") { setError("Name cannot be empty"); return }
        if (itemId === "") { setError("Item ID cannot be empty"); return }
        if (cost === 0) { setError("Cost cannot be empty"); return }
        if (description === "") { setError("Description cannot be empty"); return }

        console.log("Adding item to inventory")
        const db = firebase.firestore()
        db.collection("inventory").doc(itemId).set({
            name: name,
            itemId: itemId,
            cost: cost,
            description: description,
            quantity: quantity
        }).then(() => {
            console.log("Document successfully written!")
            setSuccess("Item successfully added to inventory")
        })
            .catch((error) => {
                console.error("Error adding document: ", error)
            });
    }

    const setSelectedItem = (item: Item) => {
        setName(item.name ?? "")
        setItemId(item.itemId ?? "")
        setCost(item.cost ?? 0)
        setDescription(item.description ?? "")
        setQuantity(item.quantity ?? 0)
        setItem(item)
        setError("")
    }


    return (
        <Container className="no-padding">
            <Row>
                <Col>
                    <h1>Inventory</h1>
                </Col>
            </Row>

            <Row style={{ borderStyle: "solid", borderColor: "lightgray", borderWidth: "0.25px" }} className="p-4"   >
                <Col xs="12" className="p-2">
                    <TextField id="item-name" label="Name of Item" value={name} variant="standard" fullWidth={true} onChange={(event) => setName(event.target.value)} />
                </Col>
                <Col xs="12" className="p-2">
                    {

                        item.itemId &&
                        <div>
                            <p>Item Id</p>
                            <div className="alert alert-secondary">
                                {item.itemId}
                            </div>
                        </div>
                    }
                    {
                        item.itemId === undefined &&
                        <TextField id="item-id" label="ID for Item" variant="standard" fullWidth={true} onChange={(event) => setItemId(event.target.value)} />
                    }
                </Col>
                <Col xs="12" className="p-2">
                    <TextField id="item-description" value={description} label="Description of Item" variant="standard" fullWidth={true} onChange={(event) => setDescription(event.target.value)} />
                </Col>
                <Col xs="12" className="p-2" md="6">
                    <TextField id="item-cost" label="Cost of Item" value={cost} variant="standard" fullWidth={true} type="number" onChange={(event) => setCost(parseInt(event.target.value))} />
                </Col>
                <Col xs="12" className="p-2" md="6">
                    <TextField id="item-cost" label="Quantity" value={quantity} variant="standard" fullWidth={true} type="number" onChange={(event) => setQuantity(parseInt(event.target.value))} />
                </Col>
                <Col xs="12" md="6" className="p-2">
                    <Button variant="contained" color="primary" className="mt-4" onClick={save}>Save</Button>
                    <Button variant="contained" color="primary" className="mt-4 ml-3" onClick={() => setSelectedItem({} as Item)}>Add New Item</Button>
                </Col>

                {
                    error &&
                    <Col xs="12" className="p-2">
                        <div className="alert alert-danger">{error}</div>
                    </Col>
                }

                {
                    success &&
                    <Col xs="12" className="p-2">
                        <div className="alert alert-success">{success}</div>
                    </Col>
                }

            </Row>
            <Row className="mt-5">
                <Col>
                    <h3>Items</h3>
                </Col>
            </Row>
            <Row className="my-4">
                <Col>
                    <strong>Total Value of Items In Stock</strong>: ${ items.reduce((total, item) => total + (item.cost * item.quantity), 0) }
                </Col>                
            </Row>
            <Row>
                <Col>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">ID</th>
                                <th scope="col">Description</th>
                                <th scope="col">Cost</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items.map((item, index) => {
                                    return (
                                        <tr onClick={() => setSelectedItem(item)} key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.itemId}</td>
                                            <td>{item.description}</td>
                                            <td>${item.cost}</td>
                                            <td>{item.quantity ?? 0}</td>
                                            <td>${(item.quantity ?? 0) * item.cost}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    )
}

export default Inventory