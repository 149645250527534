import React, { useState } from 'react'
import { Button, Col, Form, Image, Modal } from 'react-bootstrap'
import { Estimate, Panes } from '../models/models';

const PaneCount = (props: { setEstimate: any, estimate: Estimate, previousPage: () => void, nextPage: () => void }) => {

    const [validated, setValidated] = useState(false)

    let panes: Panes = props.estimate.Panes ?? {}

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        props.estimate.Panes = panes
        props.setEstimate(props.estimate)
        props.nextPage()

        setValidated(true);
    };

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Are you getting your windows cleaned?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="m-5">
                    Window panes are counted by seperate pieces of glass that have framing around it.  The diagrams below will help you with the pane count.
                </div>
                <hr />
                <Form.Row style={{ textAlign: "center" }} className="mt-3">

                    {/* Medium Panes */}
                    <Form.Group as={Col} md="4" xs="12" controlId="mediumPanes">
                        <Form.Label><strong>How many medium sized panes?</strong></Form.Label>
                        <Image src="medium.png" thumbnail />
                        <Form.Control
                            min={0}
                            onChange={e => panes.medium = parseInt(e.target.value)}
                            defaultValue={props.estimate.Panes?.medium ?? ""}
                            className="mt-3" placeholder="How many medium panes?" type="number" />
                    </Form.Group>

                    {/* Very Large Panes */}
                    <Form.Group as={Col} md="4" xs="12" controlId="veryLargePanes">
                        <Form.Label><strong>How many very large panes?</strong></Form.Label>
                        <Image src="very-large.png" thumbnail />
                        <Form.Control
                            min={0}
                            onChange={e => panes.veryLarge = parseInt(e.target.value)}
                            defaultValue={props.estimate.Panes?.veryLarge ?? ""}
                            className="mt-3" placeholder="How many very large panes?" type="number" />
                    </Form.Group>

                    {/* Large Panes */}
                    <Form.Group as={Col} md="4" xs="12" controlId="largePanes">
                        <Form.Label><strong>How many large panes?</strong></Form.Label>
                        <Image src="large.png" thumbnail />
                        <Form.Control
                            min={0}
                            onChange={e => panes.large = parseInt(e.target.value)}
                            defaultValue={props.estimate.Panes?.large ?? ""}
                            className="mt-3" placeholder="How many large panes?" type="number" />
                    </Form.Group>

                    {/* Small Panes */}
                    <Form.Group as={Col} md="4" xs="12" controlId="smallPanes">
                        <Form.Label><strong>How many small panes?</strong></Form.Label>
                        <Image src="small.png" thumbnail />
                        <Form.Control
                            min={0}
                            onChange={e => panes.small = parseInt(e.target.value)}
                            defaultValue={props.estimate.Panes?.small ?? ""}
                            className="mt-3" placeholder="How many small panes?" type="number" />
                    </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => props.previousPage()}>Previous</Button>
                <Button variant="primary" onClick={handleSubmit} >Next</Button>
            </Modal.Footer>
        </div>
    )

}

export default PaneCount