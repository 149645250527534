import firebase from "firebase";
import moment from "moment";
import CONSTANTS from "../constants";
import { Customer, Message, PayingClient, Postcard } from "../models/models";

enum CommunicationTypes {
    Postcard,
    TextMessage
}
class MessageService {


    private sendMessage(url: string, body: any, messageObject: any): Promise<boolean> {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }

        const db = firebase.firestore()
        db.collection(CONSTANTS.FIRESTORE.MESSAGES).add(messageObject)

        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then(data => {
                    console.log("Finished")
                    resolve(true)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    async sendPostcard(customer: PayingClient) {
        const body = {
            customer: {
                firstName: customer.firstName,
                address: customer.address
            }
        }


        const postcardObject: Postcard = {
            address: customer.address,
            date: new Date(),
            type: "postcard"
        }

        let url = "https://defined-cleaning.herokuapp.com/sendPostcard"

        if (process.env.NODE_ENV == "development") {
            url = "http://localhost:3000/sendPostcard"
        }

        const canSendPostcard = await this.canSendMessage(customer, CommunicationTypes.Postcard)

        if (canSendPostcard) {
            try {
                const messageSent = await this.sendMessage(url, body, postcardObject)
                return messageSent
            } catch (err) {
                return err
            }   
        }

        return new Promise((resolve, reject) => reject("Can not send postcard because you have sent one within the last thirty days"))
    }

    private canSendMessage(customer: PayingClient, type: CommunicationTypes): Promise<boolean> {
        const db = firebase.firestore()
        
        // Get any messages that have been sent to this phone number to make sure that we haven't sent a message within the past thirty days
        let query = db.collection(CONSTANTS.FIRESTORE.MESSAGES).where(CONSTANTS.FIRESTORE.PHONE_NUMBER, "==", customer.phoneNumber).get()

        if (type == CommunicationTypes.Postcard) {
            query = db.collection(CONSTANTS.FIRESTORE.MESSAGES).where(CONSTANTS.FIRESTORE.ADDRESS, "==", customer.address).get()
        }

        return new Promise((resolve, reject) => {
            query.then(result => {
                let messages: Message[] = result.docs.map(doc => doc.data() as Message)

                if (messages.length == 0) {
                    resolve(true)
                    return
                }

                const todaysDate = new Date()

                for (let index = 0; index < messages.length; index++) {
                    const message = messages[index];

                    console.log((message.date.seconds - todaysDate.getSeconds()))

                    // Check to see if any messages have been sent to this person within the last thirty days, if so don't send a message
                    if ((((todaysDate.getTime() / 1000) - message.date.seconds) / (3600 * 24)) < 30) {
                        resolve(false)
                        return
                    }
                }

                resolve(true)
            })
        })

    }

    sendNotification(customer: PayingClient, messageText: string, messageType: string): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {

            if (!customer.phoneNumber) { reject(new Error("The user has no phone number. There's no way to send a message")) }


            let url = "https://defined-cleaning.herokuapp.com/sendCustomMessage"

            if (process.env.NODE_ENV == "development") {
                url = "http://localhost:3000/sendCustomMessage"
            }

            // The URL request body
            const messageBody = {
                phoneNumber: customer.phoneNumber,
                message: messageText,
                fromNumber: "+17028205488"
            }

            const messageObject: Message = {
                phoneNumber: customer.phoneNumber ?? "",
                date: new Date(),
                type: messageType
            }

            const db = firebase.firestore()

            // Get any messages that have been sent to this phone number to make sure that we haven't sent a message within the past thirty days
            let query = db.collection(CONSTANTS.FIRESTORE.MESSAGES).where(CONSTANTS.FIRESTORE.PHONE_NUMBER, "==", customer.phoneNumber).get()

            query.then(result => {
                let messages: Message[] = result.docs.map(doc => doc.data() as Message)

                if (messages.length == 0) {
                    return this.sendMessage(url, messageBody, messageObject)
                }

                const todaysDate = new Date()

                for (let index = 0; index < messages.length; index++) {
                    const message = messages[index];

                    console.log((message.date.seconds - todaysDate.getSeconds()))

                    // Check to see if any messages have been sent to this person within the last thirty days, if so don't send a message
                    if ((((todaysDate.getTime() / 1000) - message.date.seconds) / (3600 * 24)) < 30) {
                        resolve(false)
                        return
                    }
                }

                return this.sendMessage(url, messageBody, messageObject)
            })
        })

    }


    /// TODO: Move this within the sendNotification method
    messageSentWithinThirtyDays(messageDate: any) {
        // Check to see if any messages have been sent to this person within the last thirty days, if so don't send a message
        if ((((new Date().getTime() / 1000) - messageDate.seconds) / (3600 * 24)) < 30) {
            return true
        }

        return false
    }

    sendAppointmentReminder(monthsSinceService: number) {

    }
}

export default MessageService