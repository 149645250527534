import { url } from 'inspector'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardColumns, Col, Container, Image, Modal, Navbar, Row } from 'react-bootstrap'
import '../App.css'
import TestimonialObject from '../objects/testimonialobject'
import Estimator from '../components/estimator'
import '../App.css'
import CTA from '../components/CTA'
import ReactGA from 'react-ga'
import { Player } from 'video-react'

import "../../node_modules/video-react/dist/video-react.css"; // import css
import firebase from 'firebase'

const PowerWashing = () => {

    const phoneNumberLink = "tel:7027470901"
    const phoneNumber = "(702) 747-0901"
    const discount = "20%"
    const [showModal, setShowModal] = useState(false)
    const [showDangerModal, setShowDangerModal] = useState(false)

    useEffect(() => {
        if (!firebase.app.length) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
                authDomain: 'dephyned-web.firebaseapp.com',
                projectId: 'dephyned-web'
            });
        }
    }, [])

    const CallNow = () => {
        return (
            <div className="alert alert-info p-1">
                <div className="ml-5 mt-5">All you need to do is answer 3 simple questions and you'll receive your free estimate. And you can schedule the job also, all online.</div>
                <Button className="m-5" onClick={() => { window.location.href = "https://5ve5c9rmseo.typeform.com/to/TF2lznW8" }}>Click Here To Get Your Instant Quote</Button>

            </div>
            // <Button className="mt-3" href={phoneNumberLink}>Click to call or text 📞 {phoneNumber} NOW for your<br /><strong>FREE</strong> estimate</Button>
        )
    }

    const Testimonial = (props: {
        testimonial: TestimonialObject
    }) => {

        return (
            <Card bg="light" className="mb-2">
                <Card.Header>{props.testimonial.username} says:</Card.Header>
                <Card.Body>
                    <div className="mb-3">
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                        <Image src="/star.png" width="24" />
                    </div>
                    <Card.Title>{props.testimonial.title} </Card.Title>
                    <Card.Text>
                        {props.testimonial.content}
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

    const AreYouSure = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setShowDangerModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    If you go back you'll lose any information that you've entered. Are you sure you want to go back?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        setShowModal(false)
                        setShowDangerModal(false)
                    }} >Yes</Button>
                    <Button variant="success" onClick={() => setShowDangerModal(false)} >Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const markConversion = () => {
        ReactGA.event({
            category: 'User',
            action: 'User clicked phone number to call',
            label: "User clicked call button",
            value: 10
        })
    }

    return (
        <div className="no-printme">
            {
                showDangerModal && <AreYouSure />
            }
            <Modal show={showModal} size="lg" onHide={() => setShowDangerModal(true)}>
                <Estimator />
            </Modal>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src="/logo.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Defined Window Cleaning
                </Navbar.Brand>
                <Button className="m-2" style={{}} onClick={() => { markConversion() }} href={phoneNumberLink}>Call (702) 747-0901 now<br />to schedule your <strong>FREE ESTIMATE</strong></Button>

                <Button className="m-2" onClick={() => { window.location.href = "https://5ve5c9rmseo.typeform.com/to/TF2lznW8" }}>Click Here To Get Your Instant Quote</Button>

            </Navbar>


            <div style={{ width: "100%", fontSize: "18px", lineHeight: "2.0em" }} className="mt-4" >
                <Container className="p-3" style={{ backgroundColor: "white" }}>

                    <Row>
                        <Col>
                            <a href="/">Window Cleaning</a> {'|'} <a href="/powerwashing">Power Washing</a> {'|'} <a href="/#screen-repair">Screen Repair and Building</a>
                        </Col>
                    </Row>

                    <Row className="text-center">

                        <Col xs="12" md="6" className="d-flex align-items-center text-left">
                            <div >
                                <h1><strong>Best In Las Vegas Power Washing</strong></h1>

                                <p>We power wash <strong>driveways, patios, walkways, entrances, and exterior stucco.</strong> Call us NOW for a <strong>free ESTIMATE.</strong></p>

                                <p>We power wash:</p>

                                <ul>
                                    <li><strong>Stain removal</strong> from concrete, stone, and tile</li>
                                    <li><strong>Grease stains</strong> in your driveway from leaked automobile oil</li>
                                    <li><strong>Barbeque grill stains</strong> on your patio concrete from leaked charcoal ash and leaked grease and oil</li>
                                    <li>Exterior <strong>tile </strong>(e.g. <strong>pool decks</strong>)</li>
                                    <li><strong>Exterior stucco</strong> power washing the outside walls of your home or business</li>
                                    <li><strong>Stone</strong> walkways, driveways, decorative stone</li>
                                    <li>Exterior <strong>concrete</strong> (e.g. driveway, patios, walkways, etc.)</li>
                                    <li>1-story &amp; 2-story buildings</li>
                                    <li>Residential and business</li>
                                    <li>Luxury and non-luxury homes</li>
                                </ul>
                                <p><strong>All our reviews are 5 stars</strong> &#x2B50;&#x2B50;&#x2B50;&#x2B50;&#x2B50;.</p>

                                <p><strong>* Licensed and Insured up to $1 million</strong></p>
                            </div>
                        </Col>
                        <Col xs="12" md="6" className="my-4 d-flex align-items-center">

                            <Container>
                                <Row>
                                    <Col xs="12">
                                        <Image src="power-washing/oil-before-after.jpg" thumbnail />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                            <CallNow />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardColumns>
                                <Card>
                                    <Card.Img src="power-washing/driveway-before-after.jpg" />
                                    <Card.Body>This driveway was filthy. Had never been cleaned before. But look at how nice it looked after we were done.
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img src="power-washing/dirty-clean.jpg" />
                                    <Card.Body>
                                        The customer had custom painted cement that they wanted cleaned without being damaged. Some areas of the concrete were awful, as you can see in the before section of the picture. But they were soooo happy with how it looked after we had finished.
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img src="power-washing/tires-before-after.jpg" />
                                    <Card.Body>Tires can really do a lot of damage to the way your driveway looks. Want to get those pesky tires removed from your driveway? Then contact us now so we can make your driveway look like brand new.
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img src="power-washing/trash-before-after.jpg" />
                                    <Card.Body>Do you have a dirty trash bin that you need to get cleaned? We do that as well. Why not get them cleaned soon so that your trash bin is not stinking up the surroundings?
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img src="power-washing/oil-before-after.jpg" />
                                    <Card.Body>Oil stains are not just ugly, they can also cost you money from your HOA. Oil stains can be very hard to remove, but we have the tools capable of removing those ugly oil stains.
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Player
                                            playsInline
                                            poster="power-washing/clean-tires.jpg"
                                            src="power-washing/cleaning-tires.mp4"
                                        />
                                        <div className="mt-3">
                                            Look at how our pressure washing machine strips away those tire marks and leaves the beautiful white concrete clean.
                                        </div>
                                    </Card.Body>
                                </Card>
                            </CardColumns>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mt-5" id="water-fed-pole-design">
                            <h2>We serve Las Vegas, Henderson, and Boulder City</h2>
                            <hr />

                            <p>Driveway tire marks and oil stains are ugly and embarassing!</p>

                            <p>We know you care about the beauty of your home, inside and out.</p>
                            <p>That's why we work extra hard to ensure that your driveway, patio, walkway, and home look great after our power washing services.</p>

                            <p><strong>Besides water, we always use natural products.</strong></p>

                            <p><strong>And we make sure that when power washing your home we scour every inch. &nbsp;We keep working until your stone, concrete, tile, or stucco look like new!</strong></p>

                            <p>Also...</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-5" id="water-fed-pole-design">
                            <h1>4,000 Pounds Per Square Inch of Power Washing Pressure To Remove Tough Stains</h1>

                            <p>We use <strong>water pressure of 3,500 pounds per inch</strong> to clean your home's stucco, concrete, stone, and tile.</p>

                            <p>We also use a special eco-friendly, <strong>pet-friendly power-washing soap. </strong>The soap helps us clean tough stains in concrete, stone, and tile.</p>

                            <h2>We are local experts for professional power washing in Las Vegas</h2>

                            <p>We can clean stains that you could never clean on your own.</p>

                            <p>We promise, we'll make the concrete, stucco, tile, or stone around your home <strong>look fantastic.</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1 className="text-center">Testimonials</h1>
                            <hr />

                            <div className="mt-5">
                                <CardColumns>
                                    <Testimonial testimonial={{ username: "Patricia I", title: "From Home Advisor", content: '"Our House Cleaner suggested that we have the windows of our house professionally cleaned because professionals would do a much better job than her. I’m so happy that we followed her suggestion. Defined Cleaning, did a superb job! We are so happy with the results! All the windows and screens are very clean. With the Sliding Door, I could walk into it, not realizing that the sliding door wasn’t opened. That’s how clean it is. Thank you, Defined Cleaning, for a professional job well done. I will recommend your services to others. – PE, A very satisfied customer."' }} />

                                    <Testimonial testimonial={{ username: "Alex T.", title: "From Home Advisor", content: '"Ade was on time and very professional. Our windows look great! We would recommend his window cleaning services to anyone."' }} />

                                    <Testimonial testimonial={{ username: "Andrew K.", title: "From Home Advisor", content: '"These guys did a great job. Showed up on time, performed a thorough job. Definitely will hire them next time."' }} />

                                    <Testimonial testimonial={{ username: "Meredith K.", title: "From Google Reviews", content: '"Fast friendly service. I would highly recommend!"' }} />

                                    <Testimonial testimonial={{ username: "Barek H.", title: "From Google Reviews", content: '"I like to tell people whenever I come across an honest business. You can really trust what Ade bids for the job. He was trained by a professional and does an excellent job. I don’t know what he does with the screens, but they look awesome. They are my new go to window cleaners."' }} />

                                    <Testimonial testimonial={{ username: "Jessie T.", title: "From Google Reviews", content: '"Defined Window cleaning did an amazing job...Our windows look amazing."' }} />

                                    <Testimonial testimonial={{ username: "Joseph W.", title: "From Google Reviews", content: "'I don't think my windows had been cleaned in 10 years, but Jesse and Fernando got them looking like new once again. No need to look anywhere else, go with this company, you won't regret it.'" }} />

                                    <Testimonial testimonial={{ username: "Diana S.", title: "From Yelp", content: "'Defined Window Cleaning did an amazing job! My house was getting ready to close and the mud storm had totally dirtied the windows. I had to pack and had no time to redo them. They came out in less 24 hrs and did a fantastic job at a reasonable price. The screens were as good as new, too!'" }} />

                                    <Testimonial testimonial={{ username: "Celeste C.", title: "From Yelp", content: "'Ade is very skilled and trustworthy. You can trust him to do what he says in a timely manner. Your windows will be sparkling clean in no time!'" }} />

                                    <Testimonial testimonial={{ username: "Portia H.", title: "From Yelp", content: "'If there's 10 stars here I would give him that! Ade is someone you can trust with your home and your window cleaning. He is very professional, has a very calm manner to him, and will exceed to your expectations on what your paying for. He listens to your request and will give you an honest bid, which we need nowadays. He will make sure that you will call him back for his service and you won't regret either referring him to your friends!'" }} />

                                </CardColumns>
                            </div>
                            <CallNow />
                        </Col>
                    </Row>

                    <hr />
                    <div id="screen-repair">
                        <Row className="my-5">
                            <Col xs="12" md="6">
                                <div>
                                    <h1 style={{ fontSize: "45px", fontWeight: "bold" }}>Window Screen Repair and Installation</h1>
                                </div>
                                <div>
                                    <h5>We construct and install window screens.</h5>
                                </div>
                                <div className="mt-4">
                                    Because of the intense Las Vegas sun, window screens fade over time and become brittle. Even the frames themselves can become brittle and the corners can easily break.
                                </div>

                                <div>
                                    <strong>
                                        If you have...
                                        <ul>
                                            <li>Old or bent screens frames</li>
                                            <li>Faded or torn window screens</li>
                                            <li>Have windows that need screens put on them</li>
                                        </ul>
                                    </strong>
                                </div>
                                <div>
                                    ...then call us now for your <strong>FREE at home estimate.</strong>  We will come out, measure all your windows and give you your personalized quote.
                                </div>
                                <div className="text-center mb-4">
                                    <CallNow />
                                </div>
                            </Col>
                            <Col xs="12" md="6" className="text-center my-4 d-flex align-items-center">
                                <Image src="/screen-rebuild.jpg" thumbnail />
                            </Col>
                        </Row>
                    </div>

                </Container>
                <hr />
            </div >
        </div >
    )
}

export default PowerWashing