import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Appointment, PayingClient } from '../models/models'

import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from '@mui/material'

// clientId: string,
// dateOfService: any,
// id: string,
// services: string[],
// servicesProvided: string[],
// total: number,
// notes: string,
// misc?: any,
// startTime: string,
// endTime: string
const AppointmentPage = (props: { appointmentId?: string }) => {

    const [appointment, setAppointment] = useState<Appointment>()

    const [client, setClient] = useState<PayingClient>()

    const defaultProps = {
        center: {
          lat: 10.99835602,
          lng: 77.01502627
        },
        zoom: 11
      };

    useEffect(() => {

        if (firebase.apps.length == 0) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
                authDomain: 'dephyned-web.firebaseapp.com',
                projectId: 'dephyned-web'
            });
        }

        const db = firebase.firestore()

        const getClient = async (appointment: Appointment) => {
            const client = await db.collection('clients').doc(appointment.clientId).get()
            setClient(client.data() as PayingClient)
        }

        const getAppointment = async () => {
            // Get an appointment by id
            const appointmentId = props.appointmentId
            if (appointmentId === undefined) { return }
            const appointmentDoc = await db.collection('appointments').doc(appointmentId).get()
            const myAppointment = appointmentDoc.data() as Appointment
            setAppointment(myAppointment)
            getClient(myAppointment)
        }

        getAppointment()
    }, [])

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h4>Appointment Page</h4>
                    </Col>
                </Row>
                <Row style={{ borderStyle: "solid", borderColor: "lightgray", borderWidth: "0.25px" }} className="p-4 my-4">
                    <Col xs="12">
                        <h5>Client</h5>
                    </Col>
                    <Col xs="6">
                        <Container>
                            <Row>
                                <Col xs="12">
                                    Name: {client?.firstName} {client?.lastName}
                                </Col>
                                <Col xs="12">
                                    Email: {client?.email}
                                </Col>
                                <Col xs="12">
                                    Phone: {client?.phoneNumber}
                                </Col>
                                <Col xs="12">
                                    Address: {client?.address}
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs="6">
                        <Container>
                            <Row>
                                <Col xs="12">
                                    <div style={{ height: '100vh', width: '100%' }}>
                    
                                    </div>
                                </Col>
                                <Col xs="12" className="mt-2">
                                    <Button variant="contained" color="primary" className="mt-2 mr-2">Navigate</Button>
                                    <Button variant="contained" color="primary" className="mt-2 mr-2">View Client</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

                <Row style={{ borderStyle: "solid", borderColor: "lightgray", borderWidth: "0.25px" }} className="p-4 my-4">
                    <Col xs="12" className="mb-2">
                        <h5>Appointment</h5>
                    </Col>
                    <Col xs="12">
                        {/* Date: {appointment?.dateOfService} */}
                    </Col>
                    <Col xs="12">
                        Start Time: {appointment?.startTime}
                        <hr />
                    </Col>
                    <Col xs="12">
                        End Time: {appointment?.endTime}
                        <hr />
                    </Col>
                    <Col xs="12">
                        Services: {appointment?.services}
                        <hr />
                    </Col>
                    <Col xs="12">
                        <div className="mb-1">
                            Notes:
                        </div>
                        <div>
                            {appointment?.notes}
                        </div>
                        <hr />
                    </Col>
                    <Col xs="12">
                        Total: ${appointment?.total}
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default AppointmentPage