import { AccountCircleOutlined, LocationOnOutlined, CleaningServices, PaidOutlined } from '@mui/icons-material'
import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import firebase from 'firebase'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Table } from 'react-bootstrap'
import { Appointment, Invoice, PayingClient } from '../models/models'

const Invoices = () => {

    const [invoices, setInvoices] = useState<Invoice[]>([])

    const [clients, setClients] = useState<{ [key: string]: any }>({})

    useEffect(() => {
        
        if (firebase.apps.length == 0) {
            // Initialize Cloud Firestore through Firebase
            firebase.initializeApp({
                apiKey: 'AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8',
                authDomain: 'dephyned-web.firebaseapp.com',
                projectId: 'dephyned-web'
            });
        }

        const getInvoices = async () => {
            const db = firebase.firestore()
            const invoices = (await db.collection('invoices').get()).docs.map(doc => doc.data()) as Invoice[]
            setInvoices(invoices)
        }

        getInvoices()
    }, [])

    /**
     * It loads a customer from the database and sets it in the state.
     * @param {string} id - the id of the client
     * @param {string} appointmentId - the id of the appointment
     * @returns The customer object
     */
    const loadCustomer = async (id: string, invoiceId: string) => {
        const db = firebase.firestore()
        const customer = (await db.collection('clients').doc(id).get()).data() as PayingClient
        setClients({ ...clients, [invoiceId]: customer })
        return customer
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div>
                        <h1>Invoices</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TableContainer component={Paper} className="table table-striped" style={{ borderRadius: 10, borderWidth: 0.1 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell scope="col"><AccountCircleOutlined fontSize='small' className="mr-2" /> Customer Name</TableCell>
                                    <TableCell scope="col"><LocationOnOutlined fontSize='small' className="mr-2" /> Email</TableCell>
                                    <TableCell scope="col"><CleaningServices className="mr-2" />Title</TableCell>
                                    <TableCell scope="col"><PaidOutlined className="mr-2" />Payment Link</TableCell>                                    
                                    <TableCell scope="col"><PaidOutlined className="mr-2" />Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    invoices.map((invoice: Invoice, index: number) => {
                                        if (!clients[invoice.id]) {
                                            loadCustomer(invoice.clientId, invoice.id)
                                        }

                                        return (
                                            <TableRow key={index}>
                                                <TableCell><strong>{invoice.fullName}</strong></TableCell>
                                                <TableCell>
                                                    <div>
                                                        {invoice.email}
                                                    </div>
                                                </TableCell>
                                                <TableCell>{invoice.title}</TableCell>
                                                <TableCell><a href={`${invoice.invoiceLink}`}>{invoice.invoiceLink}</a></TableCell>                                                
                                                <TableCell ><strong>${invoice.amount}</strong></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Col>
            </Row>



        </Container>


    )
}

export default Invoices