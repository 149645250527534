import React, { useEffect } from 'react'
import { useState } from 'react'
import { Row, Col, Button, Form, InputGroup, Modal, ModalBody } from 'react-bootstrap'
import { Estimate, Customer } from '../models/models'

const PersonalInformationForm = (props: { setEstimate: any, estimate: Estimate, previousPage: () => void, nextPage: () => void }) => {

    const [validated, setValidated] = useState(false)

    let customer: Customer = props.estimate.Customer ?? {}

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            props.nextPage()
            props.estimate.Customer = customer
            props.setEstimate(props.estimate)
        }

        setValidated(true);
    };

    return (
        <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Personal Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        {/* First Name */}

                        <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                required
                                onChange={e => customer.firstName = e.target.value}
                                defaultValue={props.estimate.Customer?.firstName ?? ""}
                                type="text"
                                placeholder="Enter Your First Name" />
                            <Form.Control.Feedback type="invalid">Please enter your first name</Form.Control.Feedback>
                        </Form.Group>

                        {/* Last Name */}

                        <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Your Last Name" onChange={e => customer.lastName = e.target.value} defaultValue={props.estimate.Customer?.lastName ?? ""} />
                            <Form.Control.Feedback type="invalid">Please enter your last name</Form.Control.Feedback>
                        </Form.Group>

                        {/* Street Address */}
                        <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formStreetAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Your Address" onChange={e => customer.address = e.target.value} defaultValue={props.estimate.Customer?.address ?? ""} />
                            <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
                        </Form.Group>


                        {/* Phone Number */}
                        <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formPhoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control required type="number" placeholder="Enter Your Phone Number" onChange={e => customer.phoneNumber = e.target.value} defaultValue={props.estimate.Customer?.phoneNumber ?? ""} />
                            <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                        </Form.Group>


                        {/* Zip Code */}
                        <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formZipCode">
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control required type="number" placeholder="Enter Your Zip Code" onChange={e => customer.zipCode = parseInt(e.target.value)} defaultValue={props.estimate.Customer?.zipCode ?? ""} />
                            <Form.Control.Feedback type="invalid">Please enter your zip code</Form.Control.Feedback>
                        </Form.Group>

                        {/* Email Address */}
                        <Form.Group as={Col} md="6" xs="12" className="mb-3" controlId="formEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control required type="email" placeholder="Enter Your Email Address" onChange={e => customer.email = e.target.value} defaultValue={props.estimate.Customer?.email ?? ""} />
                            <Form.Control.Feedback type="invalid">Please enter your email address</Form.Control.Feedback>
                        </Form.Group>

                        {/* Area */}
                        <Form.Group as={Col} md="6" xs="12">
                            <Form.Label>Area</Form.Label>
                            <Form.Control as="select" size="lg" onChange={e => customer.area = e.target.value} defaultValue={props.estimate.Customer?.area ?? ""} custom>
                                <option>North Las Vegas</option>
                                <option>Henderson</option>
                                <option>Lake Las Vegas</option>
                                <option>Summerlin</option>
                                <option>Boulder City</option>
                                <option>Aliante</option>
                                <option>Other</option>
                            </Form.Control>
                        </Form.Group>

                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" >Next</Button>
                </Modal.Footer>
            </Form>
        </div>
    )

}

export default PersonalInformationForm