import React from 'react';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';

const Pricing = () => {

    const PricingRow = (props: { item: string, initialPrice: number, currentPrice: number }) => {
        return (
            <Row>
                <Col lg="3" sm="5" xs="5">
                    {props.item}
                </Col>
                <Col className="d-none d-md-block" lg="5">
                    <hr style={{ borderTop: "1px dashed black" }} />
                </Col>
                <Col style={{ textAlign: "right" }} xs="7" sm="7" lg="4">
                    <strong>${props.currentPrice - 20} - ${props.currentPrice + 20}</strong> <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }} >${props.initialPrice - 20} - ${props.initialPrice + 20}</span>
                </Col>
            </Row>
        )
    }

    const PricingSection = (props: {
        item: string,
        insideOutsideIntialPrice: number,
        insideOutsideCurrentPrice: number,
        exteriorInitialPrice: number,
        exteriorCurrentPrice: number
    }) => {
        return (
            <div>
                <Row>
                    <Col>
                        <h5>{props.item}</h5>
                    </Col>
                </Row>
                <PricingRow item="Outside Only" initialPrice={props.exteriorInitialPrice} currentPrice={props.exteriorCurrentPrice} />
                <PricingRow item="Inside and Outside" initialPrice={props.insideOutsideIntialPrice} currentPrice={props.insideOutsideCurrentPrice} />
                <hr />
            </div>
        )
    }

    return (
        <Container>
            <Row>
                <Col className="my-4" style={{textAlign: "center"}}>
                    <h2>Pricing</h2>
                </Col>
            </Row>
            <Accordion>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Click to View Pricing for Single Story Homes
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <p>
                                The prices may vary depending on the configuration of your home, but we have found that the following prices are a good estimate for almost all homes. If you would like to get an exact pricing, please click on the button that says "Click Here To Get Your Instant Quote" above.
                            </p>


                            <PricingSection item="Under 2000 Square Feet" insideOutsideIntialPrice={240} insideOutsideCurrentPrice={190} exteriorInitialPrice={150} exteriorCurrentPrice={120} />

                            <PricingSection item="2000 - 2499 Square Feet" insideOutsideIntialPrice={322} insideOutsideCurrentPrice={255} exteriorInitialPrice={190} exteriorCurrentPrice={150} />

                            <PricingSection item="2500 - 2999 Square Feet" insideOutsideIntialPrice={390} insideOutsideCurrentPrice={310} exteriorInitialPrice={220} exteriorCurrentPrice={175} />

                            <PricingSection item="3000 - 3499 Square Feet" insideOutsideIntialPrice={445} insideOutsideCurrentPrice={355} exteriorInitialPrice={250} exteriorCurrentPrice={200} />

                            <PricingSection item="3500 - 3999 Square Feet" insideOutsideIntialPrice={490} insideOutsideCurrentPrice={395} exteriorInitialPrice={280} exteriorCurrentPrice={225} />

                            <PricingSection item="4000 - 4499 Square Feet" insideOutsideIntialPrice={560} insideOutsideCurrentPrice={450} exteriorInitialPrice={310} exteriorCurrentPrice={250} />

                            <PricingSection item="4500 - 4999 Square Feet" insideOutsideIntialPrice={625} insideOutsideCurrentPrice={500} exteriorInitialPrice={375} exteriorCurrentPrice={300} />

                            <Row>
                                <Col>
                                    <h5>Over 5000 Square Feet</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>Please contact us if your home is over 5000 square feet and we can provide you with your on-site FREE estimate.</Col>
                            </Row>
                            <hr />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            Click to View Pricing for Two Story Homes
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <p>
                                The prices may vary depending on the configuration of your home, but we have found that the following prices are a good estimate for almost all homes. If you would like to get an exact pricing, please click on the button that says "Click Here To Get Your Instant Quote" above.
                            </p>


                            <PricingSection item="Under 2000 Square Feet" insideOutsideIntialPrice={310} insideOutsideCurrentPrice={250} exteriorInitialPrice={190} exteriorCurrentPrice={150} />

                            <PricingSection item="2000 - 2499 Square Feet" insideOutsideIntialPrice={400} insideOutsideCurrentPrice={320} exteriorInitialPrice={220} exteriorCurrentPrice={175} />

                            <PricingSection item="2500 - 2999 Square Feet" insideOutsideIntialPrice={490} insideOutsideCurrentPrice={390} exteriorInitialPrice={260} exteriorCurrentPrice={210} />

                            <PricingSection item="3000 - 3499 Square Feet" insideOutsideIntialPrice={525} insideOutsideCurrentPrice={420} exteriorInitialPrice={280} exteriorCurrentPrice={225} />

                            <PricingSection item="3500 - 3999 Square Feet" insideOutsideIntialPrice={610} insideOutsideCurrentPrice={490} exteriorInitialPrice={350} exteriorCurrentPrice={280} />

                            <PricingSection item="4000 - 4499 Square Feet" insideOutsideIntialPrice={690} insideOutsideCurrentPrice={550} exteriorInitialPrice={400} exteriorCurrentPrice={320} />

                            <PricingSection item="4500 - 4999 Square Feet" insideOutsideIntialPrice={750} insideOutsideCurrentPrice={600} exteriorInitialPrice={470} exteriorCurrentPrice={375} />

                            <Row>
                                <Col>
                                    <h5>Over 5000 Square Feet</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>Please contact us if your home is over 5000 square feet and we can provide you with your on-site FREE estimate.</Col>
                            </Row>
                            <hr />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        </Container >

    )
}

export default Pricing;