import React, { useState } from 'react'
import { Image, Button, Col, Form, Modal, Row, Alert, Container } from 'react-bootstrap'
import { Autocomplete, TextField } from '@mui/material'
import CONSTANTS from '../constants'
import { Appointment, PayingClient } from '../models/models'
import firebase from 'firebase'
import moment from 'moment'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';

const Customer = (props: {
    showModal: boolean,
    setShowModal: any,
    customer?: PayingClient,
    customerId?: string,
    scheduleAppointment?: any
}) => {

    /// First name of the customer
    const [firstName, setFirstName] = useState("")

    /// Last name of the customer
    const [lastName, setLastName] = useState("")

    /// Address of the customer
    const [address, setAddress] = useState("")

    /// Date that the customer got the service done
    const [dateOfService, setDateOfService] = useState<any>()

    /// If the client already has a service date, then this is displayed on the initial opening of the screen
    const [initialDateOfService, setInitialDateOfService] = useState<any>()

    /// Cost of the service provided for the customer
    const [costOfService, setCostOfService] = useState(0)

    /// The phone number of the customer
    const [phoneNumber, setPhoneNumber] = useState("")

    /// How the customer found us
    const [howCustomerFoundUs, setHowCustomerFoundUs] = useState("")

    /// Service provided
    const [servicesProvided, setServicesProvided] = useState<string[]>([])

    /// Zip code of client
    const [zipCode, setZipCode] = useState("")

    /// Email
    const [email, setEmail] = useState("")

    /// Enter the notes for the customer
    const [notes, setNotes] = useState("")

    /// Whether we're currently saving information
    const [processing, setProcessing] = useState(false)

    /// Any error that has happened from firebase or user input
    const [error, setError] = useState("")

    const [customerId, setCustomerId] = useState<string>()

    const [deleteMode, setDeleteMode] = useState<boolean>(false)

    const [clients, setClients] = useState<PayingClient[]>([])

    const [startTime, setStartTime] = useState<string>("7:30 AM")

    const [endTime, setEndTime] = useState<string>("8:30 AM")

    const [customer, setCustomer] = useState<PayingClient>()

    useEffect(() => {
        if (customer) {
            setCustomerDetails(customer)
        }
        
    }, [customer])

    useEffect(() => {
        if (props.customerId) {
            firebase.firestore().collection('clients').doc(props.customerId).get().then(doc => {
                if (doc.exists) {
                    setCustomer(doc.data() as PayingClient)
                }
            })
        }

        if (props.customer) {            
            setCustomer(props.customer)
        }

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return
        } else {
            // loadCustomers()
        }

    }, [props.customer])

    const setCustomerDetails = (customer: PayingClient) => {
        setFirstName(customer.firstName ?? "")
        setLastName(customer.lastName ?? "")
        setAddress(customer.address ?? "")
        setDateOfService(customer.dateOfService)
        setCostOfService(customer.costOfService ?? 0)
        setPhoneNumber(customer.phoneNumber ?? "")
        setHowCustomerFoundUs(customer.howCustomerFoundUs ?? "Google")
        setServicesProvided(customer.servicesProvided ?? [])
        setZipCode(customer.zipCode ?? "")
        setEmail(customer.email ?? "")
        setNotes(customer.notes ?? "")
        setInitialDateOfService(customer.dateOfService ?? "")
        setCustomerId(customer.id ?? "")
    }

    const saveCustomer = (event: any) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        const db = firebase.firestore()
        setProcessing(true)

        let myCustomer: PayingClient = customer ?? {
            firstName: firstName,
            lastName: lastName,
            address: address,
            phoneNumber: phoneNumber,
            zipCode: zipCode,            
            email: email,            
            notes: notes,
            howCustomerFoundUs: howCustomerFoundUs,
            id: customerId ?? uuidv4()
        }

        if (props.scheduleAppointment) {
            const appointment: Appointment = {
                clientId: myCustomer.id,
                dateOfService: dateOfService,
                id: uuidv4(),
                services: servicesProvided,
                servicesProvided: servicesProvided,
                total: costOfService,
                notes: notes,
                misc: myCustomer.misc ?? [],
                startTime: startTime,
                endTime: endTime
            }

            props.scheduleAppointment(appointment, myCustomer)
            let appointments = myCustomer.appointments ?? []
            appointments.push(appointment)
            myCustomer.appointments = appointments
        }        

        if (customerId) {
            db.collection(CONSTANTS.FIRESTORE.CLIENTS).doc(customerId).set(myCustomer, { merge: true }).then(result => {
                props.setShowModal(false)
            }).catch(error => {
                setError(error)
            })

            return
        }

        db.collection(CONSTANTS.FIRESTORE.CLIENTS).doc(myCustomer.id).set(myCustomer).then(result => {
            props.setShowModal(false)
        }).catch(error => {
            setError(error)
        })

    }

    const serviceClicked = (event: any) => {

        const service = event.target.name

        if (servicesProvided.indexOf(service) == -1) {
            const myServicesProvided = servicesProvided.concat(service)
            setServicesProvided(myServicesProvided)
        } else {
            let index = servicesProvided.indexOf(service)
            if (index != -1) {
                const myServicesProvided = servicesProvided
                myServicesProvided.splice(index, 1)
                setServicesProvided(myServicesProvided)
            }
        }
    }

    const getCustomers = async (name: String) => {
        const db = firebase.firestore()
        const myClients = (await db.collection('clients').where('firstName', '>=', name)
            .get()).docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                } as PayingClient
            })

        setClients(myClients)
    }

    const deleteCustomer = () => {

        let customerId = props.customer?.id

        if (!customerId) { return }

        const db = firebase.firestore()
        db.collection(CONSTANTS.FIRESTORE.CLIENTS).doc(customerId).update({
            archived: true
        })

        setDeleteMode(false)
        props.setShowModal(false)

    }

    return (
        <div>
            <Modal show={deleteMode} size="sm" className="my-5">
                <Modal.Header>
                    <Modal.Title>Are you sure you want to archive this customer?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-2">
                        {props.customer?.firstName} {props.customer?.lastName}
                    </div>
                    <div className="my-2">
                        {props.customer?.address}
                    </div>
                    <Button variant="danger" onClick={deleteCustomer} className="mr-4">
                        Delete
                    </Button>
                    <Button onClick={() => setDeleteMode(false)}>
                        No
                    </Button>
                </Modal.Body>
            </Modal>
            <Modal onHide={() => props.setShowModal(false)} show={props.showModal} size="lg">
                <Form onSubmit={saveCustomer} >
                    <Modal.Header closeButton>
                        <Modal.Title>Customer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Autocomplete
                                    className="my-4"
                                    id="grouped-customers"
                                    options={clients.sort((a, b) => -b.firstName.localeCompare(a.firstName))}
                                    groupBy={(client) => client.firstName.charAt(0).toUpperCase()}
                                    getOptionLabel={(client) => `${client.firstName} ${client.lastName ?? ""}`}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        onChange={(event) => {
                                            if (event.target.value != "") {
                                                getCustomers(event.target.value)
                                            }
                                        }}
                                        label="Select Customer" />}
                                    fullWidth={true}

                                    onChange={(event, value) => {
                                        if (value) {
                                            setCustomer(value as PayingClient)
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formFirstName">
                                    <TextField
                                        value={firstName}
                                        onChange={(event) => setFirstName(event.target.value)}
                                        label="First Name"
                                        fullWidth={true}
                                        type="text" placeholder="Enter the customer's first name" />
                                    {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text> */}
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formLastName">

                                    <TextField
                                        value={lastName}
                                        label="Last Name"
                                        fullWidth={true}
                                        onChange={(event) => setLastName(event.target.value)}
                                        type="text" placeholder="Enter the customer's last name" />
                                </Form.Group>
                            </Col>

                            <Col md="12">
                                <Form.Group className="mb-3" controlId="formAddress">

                                    <TextField
                                        value={address}
                                        label="Address"
                                        fullWidth={true}
                                        onChange={(event) => setAddress(event.target.value)}
                                        type="text" placeholder="Enter the customer's address" />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formZipCode">
                                    <TextField
                                        label="Zip Code"
                                        fullWidth={true}
                                        value={zipCode}
                                        onChange={(event) => setZipCode(event.target.value)}
                                        type="text" placeholder="Enter the customer's zip code" />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <TextField
                                        label="Email"
                                        fullWidth={true}
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        type="text" placeholder="Enter the customer's email" />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formDate">
                                    <Form.Label>Enter the date of service</Form.Label>
                                    <div>
                                        <Form.Label>
                                            Initial date added: {initialDateOfService != undefined ? moment.unix(initialDateOfService.seconds).format('YYYY-MM-DD') : ""}
                                        </Form.Label>
                                    </div>
                                    <Form.Control
                                        onChange={(event) => setDateOfService(moment(event.target.value, 'YYYY-MM-DD').toDate())}
                                        type="date" placeholder="Enter the date of the service" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {/* Start Time */}
                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formDate">
                                    <TextField id="start-time" label="Start Time" type="time" defaultValue="07:30"
                                        onChange={(event) => {
                                            setStartTime(event.target.value)
                                        }}
                                        className="w-100" InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} />
                                </Form.Group>
                            </Col>

                            {/* End Time */}
                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formDate">
                                    <TextField id="end-time" label="End Time" type="time"
                                        onChange={(event) => {
                                            setEndTime(event.target.value)
                                        }} defaultValue="08:30" className="w-100" InputLabelProps={{ shrink: true, }} inputProps={{ step: 300, }} />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>

                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formCost">
                                    <TextField
                                        label="Cost of Service"
                                        fullWidth={true}
                                        value={costOfService}
                                        onChange={(event) => setCostOfService(Number(event.target.value))}
                                        type="number" placeholder="How much was the service for?" />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formPhoneNumber">

                                    <TextField
                                        label="Phone Number"
                                        fullWidth={true}
                                        value={phoneNumber}
                                        onChange={(event) => setPhoneNumber(event.target.value)}
                                        type="tel" placeholder="Enter their phone number?" />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formHowFindUs">
                                    <Form.Label>How did they find us?</Form.Label>
                                    <Form.Control
                                        onChange={(event) => setHowCustomerFoundUs(event.target.value)}
                                        defaultValue={CONSTANTS.HOWFOUNDUS.GOOGLE}
                                        value={howCustomerFoundUs}
                                        as="select" size="lg" custom>
                                        <option>{CONSTANTS.HOWFOUNDUS.GOOGLE}</option>
                                        <option>{CONSTANTS.HOWFOUNDUS.YELP}</option>
                                        <option>{CONSTANTS.HOWFOUNDUS.THUMBTACK}</option>
                                        <option>{CONSTANTS.HOWFOUNDUS.CUSTOMER_REFERRAL}</option>
                                        <option>{CONSTANTS.HOWFOUNDUS.BUSINESS_REFERRAL}</option>
                                        <option>{CONSTANTS.HOWFOUNDUS.NEW_HOME_RESOURCE}</option>
                                    </Form.Control>
                                </Form.Group>

                            </Col>

                            <Col md="6">
                                <Form.Group className="mb-3" controlId="formCost">
                                    <TextField
                                        value={notes}
                                        onChange={(event) => setNotes(event.target.value)}
                                        multiline={true}
                                        rows={4}
                                        fullWidth={true}
                                        label="Notes"
                                        placeholder="What are the details of this job? For example, is it two story? How many square feet?" />
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group
                                    onChange={(event: any) => serviceClicked(event)}
                                    className="mb-3" controlId="formServices">
                                    <Form.Label>Which service did they get?</Form.Label>
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.POWER_WASHING) != -1 }
                                        name={CONSTANTS.SERVICES.POWER_WASHING} type="checkbox" label={CONSTANTS.SERVICES.POWER_WASHING} />
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.EXT_WINDOW_CLEANING) != -1}
                                        name={CONSTANTS.SERVICES.EXT_WINDOW_CLEANING} type="checkbox" label={CONSTANTS.SERVICES.EXT_WINDOW_CLEANING} />
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.INT_WINDOW_CLEANING) != -1}
                                        name={CONSTANTS.SERVICES.INT_WINDOW_CLEANING} type="checkbox" label={CONSTANTS.SERVICES.INT_WINDOW_CLEANING} />
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.WINDOW_CLEANING) != -1}
                                        name={CONSTANTS.SERVICES.WINDOW_CLEANING} type="checkbox" label={CONSTANTS.SERVICES.WINDOW_CLEANING} />
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.SCREEN_BUILDING) != -1}                                        
                                        name={CONSTANTS.SERVICES.SCREEN_BUILDING} type="checkbox" label={CONSTANTS.SERVICES.SCREEN_BUILDING} />
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.SCREEN_REPAIR) != -1}
                                        name={CONSTANTS.SERVICES.SCREEN_REPAIR} type="checkbox" label={CONSTANTS.SERVICES.SCREEN_REPAIR} />
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.WINDOW_TINT) != -1}
                                        name={CONSTANTS.SERVICES.WINDOW_TINT} type="checkbox" label={CONSTANTS.SERVICES.WINDOW_TINT} />
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.WINDOW_TINT) != -1}
                                        name={CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD} type="checkbox" label={CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD} />
                                    <Form.Check
                                        // checked={servicesProvided.indexOf(CONSTANTS.SERVICES.WINDOW_TINT) != -1}
                                        name={CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR} type="checkbox" label={CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR} />
                                </Form.Group>
                            </Col>


                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className="mr-5" variant="danger" onClick={() => setDeleteMode(true)}>
                            Delete
                        </Button>
                        <Button variant="secondary" onClick={() => props.setShowModal(false)}>Close</Button>
                        {
                            // If the customer is being saved to the database then display a loading gif
                            processing && <Image src="loading.gif" />
                        }
                        {
                            // Only show the save changes button when the customer is not saving
                            !processing &&
                            <Button variant="primary" onClick={saveCustomer} >Save changes</Button>
                        }
                        {
                            error &&
                            <Alert variant="danger">
                                {{ error }}
                            </Alert>
                        }
                    </Modal.Footer>

                </Form>
            </Modal>
        </div >
    )

}

export default Customer