import { TextField, Button as MaterialButton, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Alert } from '@mui/material';
import firebase from 'firebase';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row, Accordion, Image } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CONSTANTS from '../constants';
import { PayingClient } from '../models/models';
import QuickbooksService from '../services/QuickbooksService';

type Dimension = {
    length: Fraction,
    width: Fraction
}

type Fraction = {
    whole: number
    top?: number,
    bottom?: number
}

type Screen = {
    id: string,
    dimension: Dimension,
    materialCost: number,
    laborCost: number,
    price: number,
    archedPrice: number,
    actualPrice?: number,
    rescreenPrice: number,
}

type AddOn = {
    name: string,
    price: number
}

type SelectedService = {
    id: string,
    name: string,
    price: number,
    dimensions: Dimension,
    addOns?: AddOn[],
    type: string
}

type Customer = {
    name: string,
    email: string,
    phone: string,
    address: string
}

const Screens = () => {

    const addRows = (): Dimension[] => {

        const dimensions: Dimension[] = []

        for (let index = 0; index < 1; index++) {
            dimensions.push({
                length: {
                    whole: 0,
                },
                width: {
                    whole: 0,
                }
            })
        }

        return dimensions
    }

    const [dimensions, setDimensions] = useState<Dimension[]>(addRows())

    const [screens, setScreens] = useState<Screen[]>([])

    const [solarScreens, setSolarScreens] = useState<Screen[]>([])

    const [totalProfit, setTotalProfit] = useState({
        solarScreens: 0,
        screens: 0
    })

    const [selectedServices, setSelectedServices] = useState<Object>({})

    const [error, setError] = useState('')

    const [meshColor, setMeshColor] = useState('')

    const [frameColor, setFrameColor] = useState('')

    const meshColors = ["Black", "Stucco", "Gray", "Beige", "White", "Brown"]
    const frameColors = ["Tan", "White", "Bronze", "Silver", "Champagne"]

    const [notes, setNotes] = useState('')

    const cost = {
        normal: {
            framing: 0.68,
            pullTabs: 0.54,
            springs: 0.69,
            corners: 0.88,
            mesh: 0.15
        },
        solar: {
            framing: 1.3,
            standOffFrame: 1.37,
            corners: 1.33,
            mesh: .85,
            mesh90: 1,
            clips: 1.2,
        }
    }

    const colPadding = { paddingLeft: 5, paddingRight: 5 }

    const [costPerSquareFoot, setCostPerSquareFoot] = useState(14.0)

    const [discount, setDiscount] = useState(0)

    const [customer, setCustomer] = useState<Customer>({
        name: '',
        email: '',
        phone: '',
        address: ''
    })

    useEffect(() => {
        setTotalProfit(getTotalProfit())
    }, [solarScreens])

    const addRow = () => {
        setDimensions([...dimensions, {
            length: {
                whole: 0,
            },
            width: {
                whole: 0,
            }
        }])
    }

    const addSelectedService = (service: SelectedService) => {
        setSelectedServices({ ...selectedServices, [service.id]: service })
    }

    /// Calculate the size that we need to cut the frames and the price of the material as well as how much we're going to make from the screens
    /// also we estimate how long it should take and calculate that into profit
    const calculateScreens = () => {
        const myScreens: Screen[] = []
        const mySolarScreens: Screen[] = []
        const costPerSquareFootSolar = costPerSquareFoot

        dimensions.map(dimension => {
            if (dimension.length.whole == 0) { return }
            let lengthAsDecimal = dimension.length.whole + ((dimension.length.top ?? 0) / (dimension.length.bottom ?? 1))
            let widthAsDecimal = dimension.width.whole + ((dimension.width.top ?? 0) / (dimension.width.bottom ?? 1))

            const normalScreenPrice = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * 5
            const solarSreenPrice = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * costPerSquareFootSolar
            const rescreenPrice = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * 7

            const fullLength = numberToFraction(`${lengthAsDecimal}`)
            const fullWidth = numberToFraction(`${widthAsDecimal}`)

            lengthAsDecimal -= 1.5
            widthAsDecimal -= 1.5

            const length = numberToFraction(`${lengthAsDecimal}`)
            const width = numberToFraction(`${widthAsDecimal}`)

            const normalFrameCost = (((lengthAsDecimal * 2) / 12) * cost.normal.framing) + (((widthAsDecimal * 2) / 12) * cost.normal.framing)
            const meshCost = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * cost.normal.mesh

            const solarFrameCost = (((lengthAsDecimal * 2) / 12) * cost.solar.framing) + (((widthAsDecimal * 2) / 12) * cost.solar.framing)
            const solarMeshCost = ((lengthAsDecimal / 12) * (widthAsDecimal / 12)) * cost.solar.mesh

            const totalNormalScreenCost = normalFrameCost + meshCost + cost.normal.pullTabs + cost.normal.springs + cost.normal.corners
            const totalSolarScreenCost = solarFrameCost + solarMeshCost + cost.solar.corners + cost.solar.clips

            const solarScreen: Screen = {
                dimension: {
                    length: fullLength,
                    width: fullWidth
                },
                materialCost: totalSolarScreenCost,
                laborCost: 20,
                price: solarSreenPrice,
                archedPrice: solarSreenPrice + 75,
                rescreenPrice: rescreenPrice,
                id: uuidv4()
            }

            if (solarScreen.price < 100) {
                solarScreen.actualPrice = solarScreen.price
                solarScreen.price = 100
            }

            if (solarScreen.rescreenPrice < 40) {
                solarScreen.rescreenPrice = 40
            }

            mySolarScreens.push(solarScreen)
        })

        setSolarScreens(mySolarScreens)
        setSelectedServices({})
    }

    const numberToFraction = (amount: string): Fraction => {
        // This is a whole number and doesn't need modification.
        if (parseFloat(amount) === parseInt(amount)) {
            return {
                whole: Number(amount)
            };
        }
        // Next 12 lines are cribbed from https://stackoverflow.com/a/23575406.
        var gcd = function (a: number, b: number): number {
            if (b < 0.0000001) {
                return a;
            }
            return gcd(b, Math.floor(a % b));
        };

        var len = amount.toString().length - 2;
        var denominator = Math.pow(10, len);
        var numerator = parseFloat(amount) * denominator;
        var divisor = gcd(numerator, denominator);
        numerator /= divisor;
        denominator /= divisor;
        var base = 0;
        // In a scenario like 3/2, convert to 1 1/2
        // by pulling out the base number and reducing the numerator.
        if (numerator > denominator) {
            base = Math.floor(numerator / denominator);
            numerator -= base * denominator;
        }

        const fraction: Fraction = {
            whole: base,
            top: Math.floor(numerator),
            bottom: Math.floor(denominator)
        }

        return fraction
    };

    const getTotalProfit = () => {
        let materialCost = solarScreens.reduce((sum, screen) => sum + screen.materialCost, 0)
        let laborCost = solarScreens.reduce((sum, screen) => sum + screen.laborCost, 0)
        let price = solarScreens.reduce((sum, screen) => sum + screen.price, 0)

        const solarScreenProfit = price - laborCost - materialCost

        materialCost = screens.reduce((sum, screen) => sum + screen.materialCost, 0)
        laborCost = screens.reduce((sum, screen) => sum + screen.laborCost, 0)
        price = screens.reduce((sum, screen) => sum + screen.price, 0)

        const screenProfit = price - laborCost - materialCost

        return {
            solarScreens: solarScreenProfit,
            screens: screenProfit
        }
    }

    const saveEstimate = async () => {
        if (solarScreens.length != Object.keys(selectedServices).length) {
            setError("Please select a service for each screen.")
            return
        }

        if (customer.name == "") { setError("Please enter a customer name above."); return }
        if (customer.address == "") { setError("Please enter an customer address above."); return }
        if (customer.phone == "") { setError("Please enter a customer phone number above."); return }
        if (customer.email == "") { setError("Please enter an customer email above."); return }
        if (meshColor == "") { setError("Please select a mesh color above."); return }
        if (frameColor == "") { setError("Please select a frame color above."); return }

        let servicesProvided = Object.values(selectedServices).map(selectedService => {
            let service: SelectedService = selectedService
            return `${getDimensionString(service.dimensions)} - ${service.name} - $${(service.price * (1 - (discount / 100))).toFixed(0)}`
        })

        let lineItems = Object.values(selectedServices).map((selectedService: SelectedService) => {
            return {
                name: selectedService.name,
                quantity: 1,
                unitPrice: selectedService.price * (1 - (discount / 100)),
                description: getDimensionString(selectedService.dimensions) + " - " + selectedService.name
            }
        })

        // Save the client to the database
        let client: PayingClient = {
            firstName: customer.name,
            address: customer.address,
            email: customer.email,
            zipCode: "00000",
            discounts: [{
                name: "Solar Screen Discount",
                amount: discount
            }],
            dateOfService: new Date(),
            costOfService: parseInt(finalPrice()),
            servicesProvided: servicesProvided,
            id: uuidv4(),
            services: Object.values(selectedServices),
            lineItems: lineItems,
            misc: {
                meshColor: meshColor,
                frameColor: frameColor,
                discount: discount
            },
            notes:
                `Here is your estimate for solar screens. Upon acceptance, 50% of the total cost will be due. The remaining 50% will be due upon completion of the job. Thank you for your business!

Notes: ${notes}

Screen Details:
Screen Mesh Color - ${meshColor}
Screen Frames Color - ${frameColor}

${discount != 0 ? `The price seen here reflects the discount of ${discount}%` : ""}`
        }

        let db = firebase.firestore()
        await db.collection("clients").doc(client.id).set(client)

        window.open(`https://payment.definedcleaning.com/createInvoice/${client.id}`, "_blank")
    }

    const getDimensionSpan = (dimension: Dimension) => {
        return (
            <span>
                {getDimensionString(dimension)}
            </span>
        )
    }

    const getDimensionString = (dimension: Dimension) => {
        return `${dimension.length.whole} ${dimension.length.top != undefined ? `${dimension.length.top}/${dimension.length.bottom}` : ""} X ${dimension.width.whole} ${dimension.width.top != undefined ? `${dimension.width.top}/${dimension.width.bottom}` : ""}`
    }

    const finalPrice = () => {
        return (Object.values(selectedServices).reduce((sum, service) => sum + service.price, 0) * (1 - (discount / 100))).toFixed(0)
    }

    return (
        <div style={{ backgroundColor: "white" }}>
            <Container style={{ backgroundColor: "white" }}>
                <div className="p-4 mt-5" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                    <Row>
                        <Col>
                            <h5>Screen Dimensions</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Make sure that you enter the dimensions the exact size that the screens are going to be built.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField variant='standard' label='Cost Per Square Foot' type='number' onChange={(event) => setCostPerSquareFoot(parseInt(event.target.value))} fullWidth={true} />
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col xs="6"><strong>Length</strong></Col>
                        <Col xs="6"><strong>Width</strong></Col>
                    </Row>
                    {
                        dimensions.map((dimension, index) => {
                            return (
                                <div>
                                    {/* Length Whole Number */}
                                    <Row>
                                        <Col xs="4" md="2">
                                            <TextField
                                                // value={dimension.length.whole}
                                                variant='standard'
                                                label='Legth Whole'
                                                onChange={(event) => {
                                                    let dimensionCopy = Object.assign({}, dimension)
                                                    dimensionCopy.length.whole = Number(event.target.value)
                                                    const dimensionsCopy = dimensions
                                                    dimensionsCopy[index] = dimension
                                                    setDimensions(dimensions)
                                                }}
                                                type="number" />
                                        </Col>


                                        {/* Length Fraction Top */}

                                        <Col xs="4" md="2">
                                            <TextField
                                                variant='standard'
                                                label='Length Fraction Top'
                                                // value={dimension.length.fractionTop}
                                                onChange={(event) => {
                                                    let dimensionCopy = Object.assign({}, dimension)
                                                    dimensionCopy.length.top = Number(event.target.value)
                                                    const dimensionsCopy = dimensions
                                                    dimensionsCopy[index] = dimension
                                                    setDimensions(dimensions)
                                                }}
                                                type="number" />
                                        </Col>


                                        {/* Length Fraction Bottom */}

                                        <Col xs="4" md="2">
                                            <TextField
                                                variant='standard'
                                                label='Length Fraction Bottom'
                                                // value={dimension.length.fractionBottom}
                                                onChange={(event) => {
                                                    let dimensionCopy = Object.assign({}, dimension)
                                                    dimensionCopy.length.bottom = Number(event.target.value)
                                                    const dimensionsCopy = dimensions
                                                    dimensionsCopy[index] = dimension
                                                    setDimensions(dimensions)
                                                }}
                                                type="number" />

                                        </Col>

                                        <Col xs="4" md="2">
                                            <TextField
                                                // value={dimension.length.whole}
                                                variant='standard'
                                                label='Width Whole'
                                                onChange={(event) => {
                                                    let dimensionCopy = Object.assign({}, dimension)
                                                    dimensionCopy.width.whole = Number(event.target.value)
                                                    const dimensionsCopy = dimensions
                                                    dimensionsCopy[index] = dimension
                                                    setDimensions(dimensions)
                                                }}
                                                type="number" />
                                        </Col>


                                        {/* Width Fraction Top */}

                                        <Col xs="4" md="2">
                                            <TextField
                                                variant='standard'
                                                label='Width Fraction Top'
                                                // value={dimension.length.fractionTop}
                                                onChange={(event) => {
                                                    let dimensionCopy = Object.assign({}, dimension)
                                                    dimensionCopy.width.top = Number(event.target.value)
                                                    const dimensionsCopy = dimensions
                                                    dimensionsCopy[index] = dimension
                                                    setDimensions(dimensions)
                                                }}
                                                type="number" />
                                        </Col>


                                        {/* Width Fraction Bottom */}

                                        <Col xs="4" md="2">
                                            <TextField
                                                variant='standard'
                                                label='Width Fraction Bottom'
                                                // value={dimension.length.fractionBottom}
                                                onChange={(event) => {
                                                    let dimensionCopy = Object.assign({}, dimension)
                                                    dimensionCopy.width.bottom = Number(event.target.value)
                                                    const dimensionsCopy = dimensions
                                                    dimensionsCopy[index] = dimension
                                                    setDimensions(dimensions)
                                                }}
                                                type="number" />
                                        </Col>
                                    </Row>

                                </div>
                            )
                        })
                    }
                    <Row>
                        <Col xs="12" md="4" className="my-3">
                            <MaterialButton fullWidth={true} variant='contained' onClick={addRow}>Add Row</MaterialButton>
                        </Col>
                        <Col xs="12" md="4" className="my-3">
                            <MaterialButton fullWidth={true} variant='contained' onClick={() => calculateScreens()}>Calculate</MaterialButton>
                        </Col>
                    </Row>

                </div>

                <div className="p-4 mt-3" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                    <Row>
                        <Col xs="12">
                            <h4>Customer Information</h4>
                        </Col>
                    </Row>
                    {/* Customer Full Name */}
                    <Row>
                        <Col xs="12" className="mt-3">
                            <TextField
                                variant='standard'
                                label='Full Name'
                                onChange={(event) => {
                                    setCustomer({
                                        ...customer,
                                        name: event.target.value
                                    })
                                }}
                                fullWidth={true}
                                type="text" />
                        </Col>

                        <Col xs="12">
                            <TextField
                                variant='standard'
                                label='Address'
                                onChange={(event) => {
                                    setCustomer({
                                        ...customer,
                                        address: event.target.value
                                    })
                                }}
                                fullWidth={true}
                                type="text" />
                        </Col>

                        <Col xs="6">
                            <TextField
                                variant='standard'
                                label='Phone Number'
                                onChange={(event) => {
                                    setCustomer({
                                        ...customer,
                                        phone: event.target.value
                                    })
                                }}
                                fullWidth={true}
                                type="text" />
                        </Col>

                        <Col xs="6">
                            <TextField
                                variant='standard'
                                label='Email'
                                onChange={(event) => {
                                    setCustomer({
                                        ...customer,
                                        email: event.target.value
                                    })
                                }}
                                fullWidth={true}
                                type="text" />
                        </Col>
                    </Row>
                </div>

                <div className="p-4 mt-3" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                    <h1>Job Details</h1>
                    <Row className="my-3">
                        <Col xs="12">
                            <strong>Estimated Time To Finish (Man Hours)</strong>
                        </Col>
                        <Col xs="12">
                            {`${(solarScreens.length * 45) / 60} Hours`}
                        </Col>
                    </Row>
                    {
                        solarScreens && solarScreens.map(screen => {
                            return (
                                <div>
                                    {
                                        selectedServices && selectedServices[screen.id] != undefined &&
                                        <Alert severity='success' className="mb-3">
                                            Selected - <strong>{getDimensionSpan(screen.dimension)} {selectedServices[screen.id].name}</strong>
                                        </Alert>
                                    }
                                    {
                                        !selectedServices[screen.id] &&
                                        <Accordion className="mb-5">
                                            <Card>
                                                <Card.Header className="mb-2">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                                        <p>
                                                            <strong>{getDimensionSpan(screen.dimension)} - NOT ARCHED Solar Screen</strong>
                                                        </p>
                                                        <p>
                                                            Select this if the window is a rectangle and has no arch. If the window has an arch, select the ARCHED Solar Screen option.
                                                        </p>
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <Row className="my-2">
                                                            <Col xs="12">

                                                                <div style={{ fontSize: "18px" }}>
                                                                    <strong>
                                                                        {getDimensionSpan(screen.dimension)} NOT ARCHED Solar Screen
                                                                    </strong>
                                                                </div>


                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "New Build - 80 Screen Mesh",
                                                                            price: screen.price,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                        })
                                                                    }}>
                                                                        New Build - 80 Screen Mesh: <strong>${screen.price.toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    {/* The cost of a grid is basically 6.25 a square foot because those full grids cost a lot more money and they take significantly more time. That's why we divide by .6 */}
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "New Build - 80 Screen Mesh and a Grid",
                                                                            price: screen.price / .69,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                        })
                                                                    }}>
                                                                        New Build - 80 Screen Mesh and a Grid: <strong> ${(screen.price / .69).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "Rescreen - 80 Screen Mesh",
                                                                            price: screen.rescreenPrice,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR
                                                                        })
                                                                    }}>
                                                                        Rescreen - 80 Screen Mesh: <strong>${(screen.rescreenPrice).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "New Build - 90 Screen Mesh",
                                                                            price: screen.price / .85,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                        })
                                                                    }}>
                                                                        New Build - 90 Screen Mesh: <strong>${(screen.price / .85).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "New Build - 90 Screen Mesh and a Grid",
                                                                            price: (screen.price / .85) / .69,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                        })
                                                                    }}>
                                                                        New Build - 90 Screen Mesh and a Grid: <strong>${(((screen.price / .85) / .69)).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "Rescreen - 90 Screen Mesh",
                                                                            price: screen.rescreenPrice * 1.2,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR
                                                                        })
                                                                    }}>
                                                                        Rescreen - 90 Screen Mesh: <strong>${((screen.rescreenPrice ?? undefined) * 1.2).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "Screen Door",
                                                                            price: screen.price / .65,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SCREEN_DOOR
                                                                        })
                                                                    }}>
                                                                        Screen Door - 80 Screen Mesh: <strong>${((screen.price ?? undefined) / .65).toFixed(0)}</strong>
                                                                    </MaterialButton>

                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "Screen Door",
                                                                            price: screen.price / .65,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SCREEN_DOOR
                                                                        })
                                                                    }}>
                                                                        Screen Door - 90 Screen Mesh: <strong>${((screen.price * 1.2 ?? undefined) / .65).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                {
                                                                    screen.actualPrice && screen.actualPrice <= 100 &&
                                                                    <div>
                                                                        <strong>Screen Actual Cost:</strong> ${screen.actualPrice.toFixed(0)}
                                                                    </div>
                                                                }
                                                                <hr style={{ color: "black", height: .5 }} />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Accordion.Collapse>

                                                {/* Arched Screens */}
                                                <Card.Header>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                                        <div style={{ fontSize: "18px" }}>
                                                            <p>
                                                                <strong>{getDimensionSpan(screen.dimension)} - ARCHED Solar Screen</strong>
                                                            </p>
                                                            <p>
                                                                Select this if the window contains an arch or is a circle.
                                                            </p>
                                                        </div>
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <Row className="my-2">
                                                            <Col xs="12">

                                                                <div style={{ fontSize: "18px" }}>
                                                                    <strong>
                                                                        {getDimensionSpan(screen.dimension)} ARCHED Solar Screen
                                                                    </strong>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "New Build - 80 Screen Mesh",
                                                                            price: screen.archedPrice,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                        })
                                                                    }}>
                                                                        New Build - 80 Screen Mesh: <strong>${screen.archedPrice.toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    {/* The cost of a grid is basically 6.25 a square foot because those full grids cost a lot more money and they take significantly more time. That's why we divide by .6 */}
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "New Build - 80 Screen Mesh and a Grid",
                                                                            price: screen.archedPrice / .75,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                        })
                                                                    }}>
                                                                        New Build - 80 Screen Mesh and a Grid: <strong> ${(screen.archedPrice / .75).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "Rescreen - 80 Screen Mesh",
                                                                            price: screen.rescreenPrice * 1.2,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR
                                                                        })
                                                                    }}>
                                                                        Rescreen - 80 Screen Mesh: <strong>${(screen.rescreenPrice * 1.2).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "New Build - 90 Screen Mesh",
                                                                            price: screen.archedPrice / .85,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                        })
                                                                    }}>
                                                                        New Build - 90 Screen Mesh: <strong>${(screen.archedPrice / .85).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "New Build - 90 Screen Mesh and a Grid",
                                                                            price: (screen.archedPrice / .85) / .75,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_BUILD
                                                                        })
                                                                    }}>
                                                                        New Build - 90 Screen Mesh and a Grid: <strong>${(((screen.archedPrice / .85) / .75)).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                <div>
                                                                    <MaterialButton className="my-3" variant='outlined' onClick={() => {
                                                                        addSelectedService({
                                                                            name: "Rescreen - 90 Screen Mesh",
                                                                            price: screen.rescreenPrice * 1.4,
                                                                            id: screen.id,
                                                                            dimensions: screen.dimension,
                                                                            type: CONSTANTS.SERVICES.SOLAR_SCREEN_REPAIR
                                                                        })
                                                                    }}>
                                                                        Rescreen - 90 Screen Mesh: <strong>${((screen.rescreenPrice ?? undefined) * 1.4).toFixed(0)}</strong>
                                                                    </MaterialButton>
                                                                </div>
                                                                {
                                                                    screen.actualPrice && screen.actualPrice <= 100 &&
                                                                    <div>
                                                                        <strong>Screen Actual Cost:</strong> ${screen.actualPrice.toFixed(0)}
                                                                    </div>
                                                                }
                                                                <hr style={{ color: "black", height: .5 }} />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion >
                                    }
                                </div>
                            )
                        })
                    }
                </div >

                {/* The selected services */}
                {
                    Object.keys(selectedServices).length > 0 &&
                    <div className="p-4 mt-3" style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                        {
                            Object.keys(selectedServices).length > 0 &&
                            <Row>
                                <Col xs="12" md="6">
                                    <Container>
                                        <Row>
                                            <Col xs="12">
                                                <Image src="/solar-screen-colors-scaled.jpg" width={300} fluid />
                                            </Col>
                                            <Col xs="12" className="mt-4">
                                                <FormControl>
                                                    <FormLabel id="demo-radio-buttons-group-label"><strong>What color for the screen mesh?</strong></FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                    >
                                                        {
                                                            meshColors.map((color, index) => {
                                                                return <FormControlLabel value={color} control={<Radio />} label={color} onChange={() => setMeshColor(color)} />
                                                            })
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col xs="12" md="6">
                                    <Container>
                                        <Row>
                                            <hr />
                                            <Col xs="12">
                                                <Image src="/screenframecolors-scaled.jpg" width={350} fluid />
                                            </Col>
                                            <Col xs="12" className="mt-3">
                                                <FormControl>
                                                    <FormLabel id="demo-radio-buttons-group-label"><strong>What color for the screen frames?</strong></FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                    >
                                                        {
                                                            frameColors.map((color, index) => {
                                                                return <FormControlLabel value={color} control={<Radio />} label={color} onChange={() => setFrameColor(color)} />
                                                            })
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>




                            </Row>
                        }
                    </div>
                }


                <div className='p-4 my-5' style={{ borderColor: "lightgray", borderWidth: "0.25px", borderStyle: "solid" }}>
                    {
                        Object.keys(selectedServices).map(serviceId => {
                            let service: SelectedService = selectedServices[serviceId]

                            return (
                                <Row className="my-3">
                                    <Col xs="6">
                                        <strong>{getDimensionSpan(service.dimensions)} - {service.name}</strong>
                                    </Col>
                                    <Col xs="4">
                                        <div className="d-flex justify-content-end">
                                            <div>
                                                ${service.price.toFixed(0)}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="2" xs="12">
                                        <div className="d-flex align-middle justify-content-end">
                                            <div>
                                                <MaterialButton color="error" variant='contained' onClick={() => {
                                                    delete selectedServices[serviceId]
                                                    setSelectedServices({ ...selectedServices })
                                                }}>
                                                    X
                                                </MaterialButton>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })
                    }

                    {/* Display the color that was selected for the screen mesh */}
                    {
                        meshColor &&
                        <Row>
                            <Col>
                                <strong>Screen Mesh Color: {meshColor}</strong>
                            </Col>
                        </Row>
                    }
                    {/* Display the color that was selected for the screen frame */}
                    {
                        frameColor &&
                        <Row>
                            <Col>
                                <strong>Screen Frame Color: {frameColor}</strong>
                            </Col>
                        </Row>
                    }
                    <hr />
                    <Row>
                        <Col xs="6">
                            <div>
                                <div>
                                    <strong>Subtotal:</strong>
                                </div>
                            </div>
                        </Col>
                        <Col xs="4">
                            <div className="d-flex justify-content-end">
                                <div>
                                    <strong>${(Object.values(selectedServices).reduce((sum, service) => sum + service.price, 0) * (1 - (discount / 100))).toFixed(0)}</strong>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col md="6" xs="12" className="my-1">
                            <div>
                                <strong>Discount: {discount}%</strong>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className="my-1">
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(0)
                                }}>
                                    0%
                                </MaterialButton>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className="my-1">
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(5)
                                }}>
                                    5%
                                </MaterialButton>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className="my-1">
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(10)
                                }}>
                                    10%
                                </MaterialButton>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className='my-1'>
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(15)
                                }}>
                                    15%
                                </MaterialButton>
                            </div>
                        </Col>

                        <Col md="1" xs="12" className='my-1'>
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(20)
                                }}>
                                    20%
                                </MaterialButton>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className='my-1'>
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(25)
                                }}>
                                    25%
                                </MaterialButton>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className='my-1'>
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(30)
                                }}>
                                    30%
                                </MaterialButton>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className='my-1'>
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(35)
                                }}>
                                    35%
                                </MaterialButton>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className='my-1'>
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(40)
                                }}>
                                    40%
                                </MaterialButton>
                            </div>
                        </Col>
                        <Col md="1" xs="12" className='my-1'>
                            <div >
                                <MaterialButton variant='contained' fullWidth={true} onClick={() => {
                                    setDiscount(45)
                                }}>
                                    45%
                                </MaterialButton>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>

                            </div>
                        </Col>
                    </Row>
                    {
                        error &&
                        <Row>
                            <Col>
                                <div>
                                    <Alert severity='error' >{error}</Alert>
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row className="my-3">
                        <Col>
                            <TextField label="Notes" variant="outlined" multiline={true} rows={5} fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col xs="12">
                            <Alert severity="warning">Be absolutely sure that everything that you entered above is correct. If you've entered information incorrectly and we order the wrong thing that's a huge problem.</Alert>
                        </Col>
                        <Col xs="12" md="3">
                            <MaterialButton fullWidth={true} className="my-3" variant='contained' onClick={() => { saveEstimate() }}>
                                Save Estimate
                            </MaterialButton>
                        </Col>
                    </Row>
                </div>
            </Container >
        </div >
    )

}

export default Screens