const CONSTANTS = {
  HOWFOUNDUS: {
    YELP: "Yelp",
    GOOGLE: "Google",
    BING: "Bing",
    FLYER: "Flyer",
    REFERRAL: "Referral",
    THUMBTACK: "Thumbtack",
    CUSTOMER_REFERRAL: "Customer Referral",
    BUSINESS_REFERRAL: "Business Referral",
    REAL_ESTATE_AGENT: "Real Estate Agent",
    NEW_HOME_RESOURCE: "New Home Resource"
  },
  FLOORS: {
    ONE_STORY: "1 story",
    TWO_STORY: "2 story",
    THREE_STORY: "3 story"
  },
  BASEMENT: {
    YES: "Yes",
    NO: "No"
  },
  HOUSESIZE: {
    SMALLEST: "Less than 1600 sq ft",
    SMALLER: "1600 - 1999 sq ft",
    SMALL: "2000 - 2499 sq ft",
    MEDIUM: "2500 - 2999 sq ft",
    MEDIUMLARGE: "3000 - 3499 sq ft",
    LARGE: "3500 - 3999 sq ft",
    VERYLARGE: "4000 - 4999 sq ft",
    HUGE: "5000 sq ft and up"
  },
  RES_COMMERCIAL: {
    RES: "Residential",
    COM: "Commercial"
  },
  YES: "Yes",
  NO: "No",
  POWERWASH: {
    DRIVEWAY: "Power Wash Driveway",
    HOME: "Exterior House Washing"
  },
  WINDOWS: {
    EXTERIOR: "Window Cleaning Exterior Only",
    BOTH: "Window Cleaning Interior and Exterior"
  },
  SCREENS: {
    REPAIR: "Screen Repair",
    BUILDING: "Screen Building"
  },
  YELP: "Yelp",
  SUMMERLIN: "Summerlin",
  BLINDS: "Clean Blinds",
  SERVICES: {
    SCREEN_DOOR: "Screen Door",
    POWER_WASHING: "Power Washing",
    EXT_WINDOW_CLEANING: "Exterior Window Cleaning",
    INT_WINDOW_CLEANING: "Interior Window Cleaning",
    WINDOW_CLEANING: "Window Cleaning",
    SCREEN_BUILDING: "Screen Building",
    SCREEN_REPAIR: "Screen Repair",
    WINDOW_TINT: "Window Tint",
    DRIVEWAY_PRESSURE_WASHING: "Pressure Wash of Driveway",
    PATIO_PRESSURE_WASHING: "Pressure Wash of Patio",
    EXTERIOR_HOUSE_WASH: "Exterior House Wash",
    SOLAR_SCREEN_BUILD: "Solar Screen Build",
    SOLAR_SCREEN_REPAIR: "Solar Screen Repair"
  },
  MESSAGETYPES: {
    REMINDER: "reminder",
    SURVEY: "survey"
  },
  FIRESTORE: {
    CLIENTS: "clients",
    SURVEYS: "surveys",
    DATE_OF_SERVICE: "dateOfService",
    ADDRESS: "address",
    MESSAGES: "messages",
    PHONE_NUMBER: "phoneNumber",
    SERVICES_PROVIDED: "servicesProvided",
    WHY_US: "whyUs",
    MONEYS_WORTH: "moneysWorth",
    ENJOYED_ABOUT_SERVICE: "enjoyedAboutService",
    IMPROVEMENTS: "improvements",
    RATING: "rating",
    REFERRALS: "referrals"
  },
  WEBVIEW_SETTINGS: {
    CUSTOMERS: "customers",
    SURVEYS: "surveys",
    INVENTORY: "inventory",
    SOLAR_SCREENS: "screens",
    APPOINTMENTS: "appointments",
    INVOICES: "invoices",
    FINANCES: "finances",
    APPOINTMENT: "appointment"
  },
  FIREBASE: {
    apiKey: "AIzaSyCrt_jTKOXaJU1dhclEXuWT7Q1bDu8_Vo8",
    authDomain: "dephyned-web.firebaseapp.com",
    projectId: "dephyned-web"
  }
};

export default CONSTANTS;
