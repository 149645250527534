import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Report } from '../report'

const Finances = () => {

    const report = Report

    const grossProfit = report.Rows.Row.filter(row => row.group === 'GrossProfit')[0].Summary.ColData[1].value

    const getReport = (startDate: string, endDate: string) => {

        fetch(`http://localhost:3500/api/quickbooks/profitloss`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiZGlyIn0..3o8uWK0Ieplge6SCVnJG4g.p7wsRLLzH8o-jho_Y-p_cx91nWfVLKKXWvcd5SJUfKs5vF-5MK90nd-fSyjXQgNbMd-J2KBS4iBtsA6KnZkOMYUbvLQ26ejcWEMi1XWhVim2pnVGwr724ffy7FZqTPiYjzR896kCQRlUEkzsU3xJKR2jLaSSjrpEiuO-mPhu1w7i1pTdS_qQHVA_8VwPPUx0S-7Q45nlj9M2YynPWgPTYyJxawj1QI-QEPhVT7nKcr35CcraXpHjhzjNZH_5a200iKJTAnMvk5RO7JMjldQiKm29MXeP2zVAuPpKlq-zOfLb3ucyLfuUL3WBlhjLiVeXaeVlvZE4wYDM_uE14nyGLVrsJrcBOaIZp5Qz9g2eluiya6JEuDet9NYfUNQK4o_NFpNsmAkxxQ2kbt5hsPch-TT8P8Da9NkUF5m-XCqu_Et1bKT-I4iDU6BW0PEkwpZIDVE12fy2iHI4dBrEKRa316CQG_YBMr2GWRqvjFjYoKNCTEW_Fm1P959LKvkAjEJOBam6eR4rYoHF0KVoAgT67KMx-UIggd9Lgpld9bldW4WuPix3VbutoLuhfMqVi5a2amp9RC3Jc41Ekrb55zYzGiXrLMXgnyJ-DfCGSAHLz9uaGyYJHf60ANnYJhYWg76BAgMDYqdKbz6a7vum_yMbb_z5n3Qb26fqGA1DfGEsisnCT28ivlCOaTSpjqMMCrIjS4XMYohlAAJCX0YKibwHFEZm0H-10oH6aCWwORH1eLmu_WBGrZlzZCit5QmEBg-m.mPUupvtETRSCPenA6Jb_oQ`
            },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
            })
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h1>Finances</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Gross Profit: <strong>${grossProfit}</strong>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Finances